// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { copiriPortalRequest } from '../api/api';
import config from '../config/config';
import loader_image from '../../assets/img/loader/small_loader.gif';
import default_org_img from '../../assets/img/logos/no_gl_seal_light.png'; // eslint-disable-next-line
const Notifications = props => {
	const { pageLanguageContent } = props;
	const [data, setData] = useState([]);
	const [checkAPI, setCheckAPI] = useState(false);
	const [showLoader, setShowLoader] = useState(true); // eslint-disable-next-line
	const [getPosterAPI, setGetPosterAPI] = useState(true);
	const MINUTE_MS = 500;
	useEffect(() => {
		/* initial to get the message list */
		getMessages();
		const interval = setInterval(() => {
			getNewMessages();
		}, MINUTE_MS);
		return () => clearInterval(interval);

		// eslint-disable-next-line
	}, []);

	/* function to get the message list */
	function getMessages() {
		setCheckAPI(false);
		setShowLoader(true);
		var org_id = localStorage.getItem('selected_org_id');
		//var org_id = 794;
		//var org_id = 11971;
		copiriPortalRequest('/organizations/' + org_id + '/messages', config.app_version, 'get')
			.then(response => {
				setShowLoader(false);
				setCheckAPI(true);
				if (response.data.messages.length > 0) {
					setData(response.data.messages);
				}
			})
			.catch(error => {
				//setCheckAPI(true);
				setShowLoader(false);
			});
	}

	/* function to get the message content when click on it*/
	function getMessageContent(msg_id) {
		setGetPosterAPI(false);
		if (document.getElementById('showContetn_' + msg_id).style.display === 'none') {
			setShowLoader(true);
			copiriPortalRequest('/messages/' + msg_id + '/details', config.app_version, 'get')
				.then(response => {
					setShowLoader(false);
					var htmlData = response.data.data.text;
					var images = response.data.data.images;
					if (images.length > 0) {
						var imageHtml = '';
						for (var im = 0; im < images.length; im++) {
							imageHtml +=
								'<img class="rounded-circle avatar-xl messageImages" src=' +
								images[im] +
								' alt="img" width="100px"  />';
						} //images_
						document.getElementById('images_' + msg_id).innerHTML = imageHtml;
					}
					document.getElementById('showFullContetn_' + msg_id).innerHTML = htmlData;
					if (htmlData.length > 250) {
						htmlData = htmlData.slice(0, 250);
						document.getElementById('showHideMore_' + msg_id).style.display = 'inline';
					}
					document.getElementById('posterName_' + msg_id).innerHTML = response.data.data.byline;
					document.getElementById('showContetn_' + msg_id).innerHTML = htmlData;
					document.getElementById('showContetn_' + msg_id).style.display = 'inline';
					if (htmlData.length > 250) {
						document.getElementById('showHideMore_' + msg_id).style.display = 'inline';
					}
				})
				.catch(error => {
					setShowLoader(false);
				});
		} else {
			//document.getElementById('showContetn_'+msg_id).style.display ='none';
		}
	}

	function showMoreLess(id, type) {
		if (type === 'more') {
			document.getElementById('showFullContetn_' + id).style.display = 'inline';
			document.getElementById('showHideLess_' + id).style.display = 'inline';
			document.getElementById('showContetn_' + id).style.display = 'none';
			document.getElementById('showHideMore_' + id).style.display = 'none';
		} else {
			document.getElementById('showContetn_' + id).style.display = 'inline';
			document.getElementById('showHideMore_' + id).style.display = 'inline';
			document.getElementById('showFullContetn_' + id).style.display = 'none';
			document.getElementById('showHideLess_' + id).style.display = 'none';
		}
	}

	/* get new added message immediately */
	function getNewMessages() {
		var newMessgae = localStorage.getItem('updatedMessage');
		if (newMessgae && newMessgae === 'true') {
			setData([]);
			setGetPosterAPI(true);
			getMessages();
			localStorage.setItem('updatedMessage', false);
		}
	}

	function getBgClass(index) {
		if (index % 2 === 0) {
			return ' bg-200';
		} else {
			return '';
		}
	}
	return (
		<Card className='h-100 mt-3 mt-lg-0'>
			<CardHeader className='bg-light d-flex justify-content-between'>
				<h5 className='mb-0'>
					{pageLanguageContent['HME001'] ? pageLanguageContent['HME001'] : ''}
					{showLoader && (
						<img
							className='rounded-circle avatar-xl'
							src={loader_image}
							alt='img'
							width='100px'
							id={''}
							style={{ marginLeft: '10px', position: 'absolute', marginTop: '-5px' }}
						/>
					)}
				</h5>
			</CardHeader>
			<div className='p-0 card-body' style={{ maxHeight: '250px', overflow: 'auto' }}>
				{data &&
					data.length > 0 &&
					data.map((item, i) => (
						<div
							className={'notification rounded-0 border-x-0 border-300 border-bottom-0' + getBgClass(i + 1)}
							key={item.id}
						>
							<div
								className='notification-avatar'
								onClick={() => getMessageContent(item.id)}
								style={{ cursor: 'pointer' }}
							>
								<div className='avatar avatar-xl mr-3'>
									{item.logo ? (
										<img className='rounded-circle ' src={item.logo} alt='' />
									) : (
										<img className='rounded-circle ' src={default_org_img} alt='' />
									)}
								</div>
							</div>
							<div className='notification-body'>
								<p className='mb-1' onClick={() => getMessageContent(item.id)} style={{ cursor: 'pointer' }}>
									<strong>{item.title} - </strong> <span className='notification-time'>{item.org_name}</span>
								</p>
								<span
									className={'notification-body'}
									id={'showFullContetn_' + item.id}
									style={{ display: 'none' }}
								></span>
								<span className={'notification-body'} id={'showContetn_' + item.id} style={{ display: 'none' }}></span>
								<span
									id={'showHideMore_' + item.id}
									className='showMoreContent'
									onClick={() => showMoreLess(item.id, 'more')}
									style={{ display: 'none' }}
								>
									<a href='/#'>... {pageLanguageContent['HME012']}</a>
								</span>
								<span
									id={'showHideLess_' + item.id}
									className='showLessContent'
									onClick={() => showMoreLess(item.id, 'less')}
									style={{ display: 'none' }}
								>
									<a href='/#'> ... {pageLanguageContent['HME013']?pageLanguageContent['HME013']:''}</a>
								</span>

								<p id={'images_' + item.id}></p>

								<p
									className='notification-time'
									id={'posterName_' + item.id}
									style={{ cursor: 'pointer' }}
									onClick={() => getMessageContent(item.id)}
								></p>
							</div>
						</div>
					))}

				{data && data.length <= 0 && checkAPI && (
					<div className='notification rounded-0'>
						<div className='notification-body' style={{ width: '100%' }}>
							<p className='mb-1' style={{ textAlign: 'center', fontWeight: 'bold' }}>
								{pageLanguageContent['HME002'] ? pageLanguageContent['HME002'] : ''}
							</p>
						</div>
					</div>
				)}
			</div>
		</Card>
	);
};

export default Notifications;
