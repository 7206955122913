/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useState, useEffect, useRef } from 'react'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
import FormGroupInput from '../../common/FormGroupInput';
import 'leaflet/dist/leaflet.css'; // eslint-disable-next-line
import { Map, Marker, Popup, Circle, TileLayer } from 'react-leaflet';
import 'leaflet.tilelayer.colorfilter';
import L from 'leaflet'; // eslint-disable-next-line
import 'react-leaflet-fullscreen/dist/styles.css';
// eslint-disable-next-line
import {
	Row,
	Col,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	Input,
	Label,
	FormGroup
} from 'reactstrap';
import icon from '../../../assets/img/leaflet-icon/marker-icon.png';
import iconShadow from '../../../assets/img/leaflet-icon/marker-icon.png'; // eslint-disable-next-line
import image_loader from '../../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
import CopiriLoader from '../../common/CopiriLoader';
import {genPageData} from '../../langconfig/langconfig'; // eslint-disable-next-line
const filter = ['bright:101%', 'contrast:101%', 'hue:23deg', 'saturate:225%'];
const LodgeAddressModal = props => {
	// eslint-disable-next-line
	const { lodgeData, pageLanguageContent, id, updateLodgeModal } = props; // eslint-disable-next-line
	const [revalidateMessage, setRevalidateMessage] = useState(false);
	const [lat, setLat] = useState();
	const [long, setLong] = useState();
	const [countryData, setCountryData] = useState([]);
	const [statesData, setStatesData] = useState([]);
	const [dcolor, setDcColor] = useState('transparent');
	const [showLoader, setShowLoader] = useState(false);
	const [inputField, setInputField] = useState({
		city: '',
		country: '',
		facility: '',
		postcode: '',
		state_id: '',
		state_text: '',
		street_1: '',
		street_2: '',
		street_3: ''
	});

	/* input validation constants */
	const [validation, setValidation] = useState({
		error_city: '',
		error_postal_code: '',
		error_state: '',
		error_country: ''
	});

	// eslint-disable-next-line
	const [checkDataChange, setCheckDataChange] = useState(false);
	let map = useRef(null); // eslint-disable-next-line
	const egMap = useRef();

	useEffect(() => {
		if (props.showHide) {
			getCountryData(props.id);
		}
		// eslint-disable-next-line
	}, [props.showHide]);

	/* function to get the input data */
	const inputsHandler = e => {
		setCheckDataChange(true);
		if (e.target.name === 'country') {
			getStatesData(e.target.value, 'check');
		}
		//setInputField( {[e.target.name]: e.target.value} )
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};

	// function to get the lodge map data
	function getCountryData(id) {
		copiriPortalRequest('/canon/countries', config.app_version, 'get')
			.then(response => {
				if (response.data.length > 0) {
					setCountryData(response.data);
				}
				if (lodgeData.updates.address['flag']) {
					getUpdatedAddress(lodgeData.updates.address['content']);
				}
				setLat(parseFloat(lodgeData.location.latitude).toFixed(7));
				setLong(parseFloat(lodgeData.location.longitude).toFixed(7));
				L.tileLayer
					.colorFilter('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
						attribution: null,
						transparent: true,
						url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
						filter: filter
					})
					.addTo(map.current.leafletElement);
				map.invalidateSize();
			})
			.catch(error => {});
	}

	/* function to get the address data and update input fields */
	function getUpdatedAddress(data) {
		const { city, country, facility, postcode, state_id, state_text, street_1, street_2, street_3 } = data;
		setInputField({
			...inputField,
			city,
			country,
			facility,
			postcode,
			state_id,
			state_text,
			street_1,
			street_2,
			street_3
		});
		if (country !== '') {
			getStatesData(country);
		}
	}

	/* function to get the country state data */
	function getStatesData(country_id, state_text = '') {
		copiriPortalRequest('/canon/countries/' + country_id + '/states', config.app_version, 'get')
			.then(response => {
				if (response.data.length > 0) {
					setStatesData(response.data);
				} else {
					setStatesData([]);
				}
			})
			.catch(error => {});
	}

	/* default map marker settings */
	let DefaultIcon = L.icon({
		iconSize: [25, 41],
		iconAnchor: [10, 41],
		popupAnchor: [2, -40],
		iconUrl: icon,
		shadowUrl: iconShadow
	});
	L.Marker.prototype.options.icon = DefaultIcon;

	/* trigger function when map pin moves */
	const eventHandlers = event => {
		setCheckDataChange(true);
		var distance = getDistance([lat, long], [event.target._latlng.lat, event.target._latlng.lng]);
		document.getElementById('cordinates').innerHTML =
			Number(event.target._latlng.lat).toFixed(4) + ' ,' + Number(event.target._latlng.lng).toFixed(4);
		//cordinates
		if (distance >= 101) {
			setDcColor('black');
			document.getElementById('confirmValidatebutton').disabled = true;
			document.getElementById('updateAddrees').innerHTML = props.pageLanguageContent['PRT113'];
			document.getElementById('newLat').innerHTML = event.target._latlng.lat;
			document.getElementById('newLong').innerHTML = event.target._latlng.lng;
			document.getElementById('closerPin').innerHTML = props.pageLanguageContent['PRT133'];
			setDcColor('black');
		} else {
			document.getElementById('confirmValidatebutton').disabled = false;
			document.getElementById('updateAddrees').innerHTML = props.pageLanguageContent['PRT113'];
			document.getElementById('newLat').innerHTML = event.target._latlng.lat;
			document.getElementById('newLong').innerHTML = event.target._latlng.lng;
			document.getElementById('closerPin').innerHTML = '';
			setDcColor('transparent');
		}
	};

	/* function to get distance when pin moves*/
	function getDistance(origin, destination) {
		// return distance in meters
		var lon1 = toRadian(origin[1]),
			lat1 = toRadian(origin[0]),
			lon2 = toRadian(destination[1]),
			lat2 = toRadian(destination[0]);

		var deltaLat = lat2 - lat1;
		var deltaLon = lon2 - lon1;

		var a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
		var c = 2 * Math.asin(Math.sqrt(a));
		var EARTH_RADIUS = 6371;
		return c * EARTH_RADIUS * 1000;
	}
	// return degree
	function toRadian(degree) {
		return (degree * Math.PI) / 180;
	}

	/* function to update the address */
	function updateAddress() {
		if (checkDataChange) {
			let errors = { ...validation };
			var errorCount = 0;

			if (!inputField.city.trim()) {
				errors.error_city = pageLanguageContent['CLD071'];
				errorCount++;
			} else {
				errors.error_city = '';
			}

			if (statesData.length > 0) {
				if (!inputField.state_id) {
					errors.error_state = pageLanguageContent['CLD071'];
					errorCount++;
				} else {
					errors.error_state = '';
				}
			}

			if (!inputField.postcode.trim()) {
				errors.error_postal_code = pageLanguageContent['CLD071'];
				errorCount++;
			} else {
				errors.error_postal_code = '';
			}

			if (!inputField.country) {
				errors.error_country = pageLanguageContent['CLD071'];
				errorCount++;
			} else {
				errors.error_country = '';
			}

			setValidation(errors);
			if (errorCount <= 0) {
				setShowLoader(true);
				var sendFormData = {};
				var sendData = {};
				sendFormData['street_1'] = inputField['street_1'];
				sendFormData['street_2'] = inputField['street_2'];
				sendFormData['street_3'] = inputField['street_3'];
				sendFormData['city'] = inputField['city'];

				if (statesData.length > 0) {
					sendFormData['state_id'] = inputField['state_id'];
					sendFormData['state'] = '';
				} else {
					sendFormData['state_id'] = 0;
					sendFormData['state'] = inputField['state_text'];
				}
				sendFormData['postcode'] = inputField['postcode'];
				sendFormData['facility'] = inputField['facility'];
				sendFormData['country'] = inputField['country'];
				if (document.getElementById('newLat').innerHTML !== '' && document.getElementById('newLong').innerHTML !== '') {
					sendFormData['latitude'] = document.getElementById('newLat').innerHTML;
					sendFormData['longitude'] = document.getElementById('newLong').innerHTML;
				} else {
					sendFormData['latitude'] = lat;
					sendFormData['longitude'] = long;
				}
				sendData['value'] = sendFormData;
				copiriPortalRequest('/organizations/' + lodgeData['id'] + '/address', config.app_version, 'patch', sendData)
					.then(response => {
						setShowLoader(false);
						if (response.data.status && response.data.status === 'SUCCESS') {
							toast.success(pageLanguageContent['CLD119']);
							props.closeModal();
							updateLodgeModal('address', [inputField['street_1'], inputField['city'], inputField['postcode']]);
						}
					})
					.catch(error => {
						setShowLoader(false);
					});
			}
		}
	}
	return (
		<div>
			<Modal isOpen={props.showHide} toggle={props.closeModal} id='lodge_modal'>
				<ModalHeader toggle={props.closeModal}>
					{pageLanguageContent['CLD128'] ? pageLanguageContent['CLD128'] : ''}
				</ModalHeader>
				<ModalBody>
					<Form>
						<Row>
							<Col lg={12}>
								<FormGroupInput
									id='building-name'
									label={pageLanguageContent['CLD050'] ? pageLanguageContent['CLD050'] : ''}
									type='text'
									name='facility'
									onChange={inputsHandler}
									value={inputField.facility}
								/>
							</Col>

							<Col lg={12}>
								<FormGroupInput
									id='street-address'
									label={pageLanguageContent['CLD051'] ? pageLanguageContent['CLD051'] : ''}
									placeholder=''
									type='text'
									name='street_1'
									onChange={inputsHandler}
									value={inputField.street_1}
								/>
							</Col>

							<Col lg={12}>
								<FormGroupInput
									id='street-address-line-2'
									label={pageLanguageContent['CLD052'] ? pageLanguageContent['CLD052'] : ''}
									placeholder=''
									type='text'
									name='street_2'
									onChange={inputsHandler}
									value={inputField.street_2}
								/>
							</Col>

							<Col lg={12}>
								<FormGroupInput
									id='street-address-line-3'
									label={pageLanguageContent['CLD053'] ? pageLanguageContent['CLD053'] : ''}
									placeholder=''
									type='text'
									name='street_3'
									onChange={inputsHandler}
									value={inputField.street_3}
								/>
							</Col>

							<Col lg={6}>
								<FormGroupInput
									id='city'
									label={pageLanguageContent['CLD054'] ? pageLanguageContent['CLD054'] : ''}
									placeholder=''
									type='text'
									name='city'
									onChange={inputsHandler}
									value={inputField.city}
								/>
								{validation.error_city && <p className='formValidationError'>{validation.error_city}</p>}
							</Col>

							<Col lg={6}>
								<FormGroupInput
									id='postal-code'
									label={pageLanguageContent['CLD055'] ? pageLanguageContent['CLD055'] : ''}
									placeholder=''
									type='text'
									name='postcode'
									onChange={inputsHandler}
									value={inputField.postcode}
								/>
								{validation.error_postal_code && <p className='formValidationError'>{validation.error_postal_code}</p>}
							</Col>

							<Col lg={6}>
								<FormGroup>
									<Label for='lodgeCountry'>{pageLanguageContent['CLD056'] ? pageLanguageContent['CLD056'] : ''}</Label>
									<select
										className='form-control'
										id='selectCountry'
										name='country'
										onChange={inputsHandler}
										value={inputField.country}
									>
										<option value=''>{pageLanguageContent['CLD056'] ? pageLanguageContent['CLD056'] : ''}</option>
										{countryData.length > 0 &&
											countryData.map((item, i) => (
												<option value={item.id} key={item.id}>
													{item.name}
												</option>
											))}
									</select>
								</FormGroup>
								{validation.error_country && <p className='formValidationError'>{validation.error_country}</p>}
							</Col>

							<Col md={6}>
								<FormGroup>
									<Label for='lodgeState'>{pageLanguageContent['CLD057'] ? pageLanguageContent['CLD057'] : ''}</Label>

									{statesData.length > 0 ? (
										<select
											className='form-control'
											onChange={inputsHandler}
											value={inputField.state_id}
											name='state_id'
											id='selectState'
										>
											<option value={''}>{pageLanguageContent['CLD057'] ? pageLanguageContent['CLD057'] : ''}</option>
											{statesData.length > 0 &&
												statesData.map((item, i) => (
													<option value={item.id} key={item.id}>
														{item.name}
													</option>
												))}
										</select>
									) : (
										<Input
											type='text'
											id='state'
											placeholder=''
											name='state_text'
											onChange={inputsHandler}
											value={inputField.state_text}
											className='form-control'
										/>
									)}
								</FormGroup>
								{validation.error_state && <p className='formValidationError'>{validation.error_state}</p>}
							</Col>

							<Col md={12}>
								<Button color='primary' className='btn' style={{ width: '100%' }}>
									{pageLanguageContent['CLD058'] ? pageLanguageContent['CLD058'] : ''}
								</Button>
							</Col>
						</Row>

						<p></p>
						<p style={{ fontSize: '12px' }}>
							<b>
								{pageLanguageContent['CLD060'] ? pageLanguageContent['CLD060'] : ''}:{' '}
								<span id='cordinates'>
									{lat}, {long}
								</span>
							</b>
						</p>
						<p />

						<Row>
							<Col lg={12}>
								<div className='alert alert-warning' role='alert'>
									<i className='fas fa-exclamation-triangle'></i>{' '}
									<b>{pageLanguageContent['CLD061'] ? pageLanguageContent['CLD061'] : ''}</b>
									<br />
									{pageLanguageContent['CLD062'] ? pageLanguageContent['CLD062'] : ''}
									<br />
								</div>
							</Col>
						</Row>

						<Row>
							<Col md={12} style={{ height: '250px' }}>
								{lat && long ? (
									<Map center={[lat, long]} zoom={17} style={{ minHeight: 250 }} ref={map}>
										<Marker position={[lat, long]} draggable={true} onDrag={eventHandlers}>
											<Circle
												center={{ lat: lat, lng: long }}
												fillColor={dcolor}
												radius={100}
												color={dcolor}
												id='addressMapCircle'
											/>
										</Marker>
									</Map>
								) : null}
							</Col>
						</Row>
					</Form>
				</ModalBody>
				<ModalFooter>
					<p
						style={{ position: 'absolute', left: '10px', color: 'red', width: '50%', fontSize: '11px' }}
						id='closerPin'
					></p>
					<Button color='secondary' onClick={props.closeModal}>
						{genPageData['GENCLS'] ? genPageData['GENCLS'] : ''}
					</Button>
					<Button color='primary' id={'confirmValidatebutton'} onClick={updateAddress}>
						{showLoader ? (
							<img src={image_loader} alt='loader' height='20' width='20' />
						) : genPageData['GENUPD'] ? (
							genPageData['GENUPD']
						) : (
							''
						)}
					</Button>
					<span id='updateAddrees' className='hiden'></span>
					<span id='newLat' className='hiden'></span>
					<span id='newLong' className='hiden'></span>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default LodgeAddressModal;
