import React, { useState, Fragment } from 'react';
import { copiriPortalRequest } from '../api/api';
import config from '../config/config';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Row, Input, Form, Button } from 'reactstrap';
import ButtonImgPostCreate from './ButtonImgPostCreate';
import FalconCardHeader from '../common/FalconCardHeader'; // eslint-disable-next-line
import postImage from '../../assets/img/illustrations/image.svg';
import loader_image from '../../assets/img/loader/small_loader.gif';
import {genPageData} from '../langconfig/langconfig'; // eslint-disable-next-line
const PostMessageForm = props => {
	// eslint-disable-next-line
	const { pageLanguageContent } = props; // eslint-disable-next-line
	const [privacy, setPrivacy] = useState('public'); // eslint-disable-next-line
	const [text, setText] = useState('');
	const [title, setTitle] = useState('');
	const [showLoader, setShowLoader] = useState(false); // eslint-disable-next-line
	const [files, setFiles] = useState([]);
	const handleSubmit = e => {
		e.preventDefault(); // eslint-disable-next-line
		var imagefile = document.querySelector('#file');
		var org_id = localStorage.getItem('selected_org_id');
		//var title = 'TITLE1';
		var type = 'a';
		var data = {};
		data['org'] = org_id;
		data['title'] = title;
		data['text'] = text;
		data['type'] = type;
		if (title === '') {
			alert(pageLanguageContent['HME008']);
			return false;
		} else if (text === '') {
			alert(pageLanguageContent['HME009']);
			return false;
		} else if (files.length > 0) {
			if (files.length > 10) {
				alert(pageLanguageContent['HME010']);
				return false;
			} else {
				uploadMessageData(data);
			}
		} else {
			uploadMessageData(data);
		}
	};

	function uploadMessageData(data) {
		setShowLoader(true);
		copiriPortalRequest('/messages', config.app_version, 'post', data)
			.then(response => {
				if (response.status === 200) {
					localStorage.setItem('updatedMessage', true); // eslint-disable-next-line
					var newMsgArr = new Array();
					newMsgArr.push(response.data.published);
					if (files.length > 0) {
						submitFiles(files, response.data.published.id);
					}
					localStorage.setItem('updatedMessageData', JSON.stringify(newMsgArr));
					setShowLoader(false);
					toast.success(pageLanguageContent['HME011']);
					setText('');
					setTitle('');
				}
			})
			.catch(error => {
				setShowLoader(false);
				if (error.response) {
					toast.error(error.response.data.result);
				}
			});
	}

	function submitFiles(files, id) {
		for (var i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append('userimage', files[i]);
			formData.append('source', 'announcement');
			formData.append('id', id);
			formData.append('type', 'a');
			copiriPortalRequest('/images', config.app_version, 'post', formData)
				.then(response => {})
				.catch(error => {});
		}
	}
	/* function to get image url for cropping and show modal */
	const getImageUrl = event => {
		setFiles(event.target.files);
	};

	/* function to trigger input file */
	function clickInputFile() {
		//alert('sfdsf');
		document.getElementById('uploadCaptureInputFile').click();
	}
	return (
		<Card className='h-100 mt-3 mt-lg-0'>
			<FalconCardHeader
				title={<Fragment>{pageLanguageContent['HME003'] ? pageLanguageContent['HME003'] : ''}</Fragment>}
			/>
			<CardBody className='p-0'>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col className='col-lg-12'>
							<div className='form-group messageGroupInput'>
								<Input
									className='form-control'
									placeholder={pageLanguageContent['HME004'] ? pageLanguageContent['HME005'] : ''}
									type='text'
									rows='4'
									value={title}
									onChange={({ target }) => {
										setTitle(target.value);
									}}
								/>
							</div>
						</Col>
						<Col className='col-lg-12'>
							<div className='form-group messageGroupInput'>
								<Input
									className='form-control'
									placeholder={pageLanguageContent['HME005'] ? pageLanguageContent['HME005'] : ''}
									type='textarea'
									rows='4'
									spellCheck='false'
									value={text}
									onChange={({ target }) => {
										setText(target.value);
									}}
								/>
							</div>
						</Col>
					</Row>
					<Row noGutters className='justify-content-between mt-3 px-3 pb-3'>
						<Col className='col' onClick={() => clickInputFile()}>
							<ButtonImgPostCreate imgSrc={postImage}>
								<span className='d-none d-md-inline-block'>
									{pageLanguageContent['HME006'] ? pageLanguageContent['HME006'] : ''}
								</span>
							</ButtonImgPostCreate>
						</Col>
						<Input
							type='file'
							name='userimage'
							onChange={getImageUrl}
							multiple
							id='uploadCaptureInputFile'
							accept='image/*'
							hidden
						/>

						<Col xs='auto'>
							{showLoader && (
								<img
									className='rounded-circle avatar-xl'
									src={loader_image}
									alt='img'
									width='100px'
									style={{ position: 'absolute', marginTop: '-2px', marginLeft: '-30px' }}
								/>
							)}
							<Button className='px-4 px-sm-5' type='submit' color='primary' size='sm'>
								{genPageData['GENSMT'] ? genPageData['GENSMT'] : ''} 
							</Button>
						</Col>
					</Row>
				</Form>
			</CardBody>
		</Card>
	);
};

export default PostMessageForm;
