// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Button, Card, CardHeader, CardBody, Label, FormGroup, Input, CustomInput } from 'reactstrap'; // eslint-disable-next-line
import { copiriPortalRequest, copiriAxiosPost, checkValidation } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import loader_image from '../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
import Datetime from 'react-datetime';
import moment from 'moment';
import { toast } from 'react-toastify';
const AddNewMeeting = props => {
	const { updateUpcomngMeeting, widgetLanguageContent } = props;
	const [satrtDate, setStartDate] = useState(new Date()); // eslint-disable-next-line
	const [endDate, setEndDate] = useState(new Date(Date.now() + 1 * (60 * 60 * 1000))); // eslint-disable-next-line
	const [orgList, setOrgList] = useState([]); // eslint-disable-next-line
	const [meetingTypesData, setMeetingTypesData] = useState([]); // eslint-disable-next-line
	const [step1, setStep1] = useState(true); // eslint-disable-next-line
	const [step2, setStep2] = useState(false); // eslint-disable-next-line
	const [virtual, setVirtual] = useState(false); // eslint-disable-next-line
	const [sharing, setSharing] = useState(false); // eslint-disable-next-line
	const [webCheckIn, setWebCheckIn] = useState(false); // eslint-disable-next-line
	const [showHideVirtualOptions, setShowHideVirtualOptions] = useState(false); // eslint-disable-next-line
	const [showHideSubmitLoader, setShowHideSubmitLoader] = useState(false); // eslint-disable-next-line

	const initialState = {
		meetingHost: '',
		meetingType: '',
		meetingName: '',
		eventUrl: '',
		eventId: '',
		eventPasscode: ''
	};

	const [state, setState] = useState(initialState);
	var yesterday = moment().subtract(1, 'day');
	var valid = function(current) {
		return current.isAfter(yesterday);
	};

	const initialErrorState = {
		error_meeting_host: '',
		error_meeting_type: '',
		error_meeting_name: '',
		error_event_url: '',
		error_start_date: '',
		error_end_date: ''
	};

	/* input validation constants*/
	const [validation, setValidation] = useState(initialErrorState);

	useEffect(() => {
		getOrgList();
		getMeetingType();
		// eslint-disable-next-line
	}, []);

	function getOrgList() {
		const formdata = new FormData();
		formdata.append('action', 'admin_dropdown');
		copiriAxiosPost(config.portal_url, formdata)
			.then(response => {
				if (response.data.length > 0) {
					setOrgList(response.data);
				} else {
					setOrgList([]);
				}
			})
			.catch(error => {});
	}

	function getMeetingType() {
		copiriPortalRequest('/canon/types/meetings', config.app_version, 'get')
			.then(response => {
				if (response.data.length > 0) {
					setMeetingTypesData(response.data);
				} else {
					setMeetingTypesData([]);
				}
			})
			.catch(error => {});
	}

	/* function to check the value of input box */
	const onChangeDate = event => {
		setStartDate(new Date(event));
	};

	//onChangeEndDate
	/* function to check the value of input box */
	const onChangeEndDate = event => {
		event.preventDefault();
		setEndDate(new Date(event));
	};

	//onChangeVirtual
	const onChangeVirtual = event => {
		setVirtual(!virtual);
		if (!virtual) {
			setShowHideVirtualOptions(true);
		} else {
			setShowHideVirtualOptions(false);
		}
	};

	const handleOnChange = (e, type) => {
		const { value } = e.target;
		setState({
			...state,
			[type]: value
		});
	};

	const proceedStep2 = () => {
		let errors = { ...validation };
		var errorCount = 0;

		if (!state.meetingHost.trim()) {
			errors.error_meeting_host = widgetLanguageContent['WMT001'];
			errorCount++;
		} else {
			errors.error_meeting_host = '';
		}

		if (!state.meetingType.trim()) {
			errors.error_meeting_type = widgetLanguageContent['WMT001'];
			errorCount++;
		} else {
			errors.error_meeting_type = '';
		}

		if (!state.meetingName.trim()) {
			errors.error_meeting_name = widgetLanguageContent['WMT001'];
			errorCount++;
		} else {
			errors.error_meeting_name = '';
		}
		setValidation(errors);
		if (errorCount <= 0) {
			setStep1(false);
			setStep2(true);
		}
	};

	const goBack = () => {
		setStep1(true);
		setStep2(false);
	};

	//handleSubmit
	const handleSubmit = () => {
		// eslint-disable-next-line
		const { meetingHost, meetingType, meetingName, eventUrl, eventId, eventPasscode } = state;
		let errors = { ...validation };
		var errorCount = 0;
		//var org_id = localStorage.getItem('selected_org_id');

		if (virtual) {
			if (!state.eventUrl.trim()) {
				errors.error_event_url = widgetLanguageContent['WMT001'];
				errorCount++;
			} else {
				errors.error_event_url = '';
			}

			if (state.eventUrl !== '') {
				var checkError = checkValidation(state.eventUrl, 'url');

				if (!checkError) {
					errors.error_event_url = widgetLanguageContent['WMT002'];
					errorCount++;
				} else {
					errors.error_event_url = '';
				}
			}
		}

		setValidation(errors);
		if (errorCount <= 0) {
			var currentSatrtDate = new Date(satrtDate);
			var startDateTime =
				currentSatrtDate.getFullYear() +
				'-' +
				(currentSatrtDate.getMonth() + 1) +
				'-' +
				currentSatrtDate.getDate() +
				'  ' +
				currentSatrtDate.getHours() +
				':' +
				currentSatrtDate.getMinutes() +
				':' +
				currentSatrtDate.getSeconds();
			var currentEndDate = new Date(endDate);
			var endDateTime =
				currentEndDate.getFullYear() +
				'-' +
				(currentEndDate.getMonth() + 1) +
				'-' +
				currentEndDate.getDate() +
				' ' +
				currentEndDate.getHours() +
				':' +
				currentEndDate.getMinutes() +
				':' +
				currentEndDate.getSeconds();
			var sendFormData = {};
			sendFormData['organization_id'] = meetingHost;
			sendFormData['meetingtype_id'] = meetingType;
			//sendFormData['meeting_code'] = '';
			sendFormData['meeting_name'] = meetingName;
			sendFormData['description '] = '';
			sendFormData['public'] = sharing;
			sendFormData['notify'] = webCheckIn;
			sendFormData['meeting_start'] = startDateTime;
			sendFormData['meeting_end'] = endDateTime;
			sendFormData['is_virtual'] = virtual;
			sendFormData['event_id'] = eventId;
			sendFormData['event_pass'] = eventPasscode;
			sendFormData['event_url'] = eventUrl;

			const params = new URLSearchParams({
				organization_id: meetingHost,
				meetingtype_id: meetingType,
				meeting_name: meetingName,
				meeting_code: '',
				description: '',
				meeting_start: startDateTime,
				meeting_end: endDateTime,
				is_virtual: virtual,
				public: sharing,
				notify: webCheckIn,
				event_id: eventId,
				event_pass: eventPasscode,
				event_url: eventUrl
			}).toString();

			setShowHideSubmitLoader(true);
			copiriPortalRequest('/meetings?' + params, config.app_version, 'post')
				.then(response => {
					//copiriPortalRequest('/meetings',config.app_version,'post',sendFormData).then(response => {
					if (response.status && response.status === 200) {
						toast.success(widgetLanguageContent['WMT003']);
						updateUpcomngMeeting();
					}
					setShowHideSubmitLoader(false);
					setState(initialState);
					setValidation(initialErrorState);
					setStartDate(new Date());
					setEndDate(new Date(Date.now() + 1 * (60 * 60 * 1000))); // eslint-disable-next-line
					setVirtual(false);
					setSharing(false);
					setWebCheckIn(false);
					setStep1(true);
					setStep2(false);
				})
				.catch(error => {
					setShowHideSubmitLoader(false);
					if (error.response) {
						toast.error(error.response.data.result);
					}
				});
		}
	};

	return (
		<Card className='h-100 mt-3 mt-lg-0'>
			<CardHeader className='bg-light d-flex justify-content-between'>
				<h5 className='mb-0'>{widgetLanguageContent['WMT004'] ? widgetLanguageContent['WMT004'] : ''}</h5>
			</CardHeader>
			<CardBody className='fs--1'>
				<div className='p-0 card-body'>
					{step1 && (
						<>
							<FormGroup>
								<Label for='lodgeCountry'>
									{widgetLanguageContent['WMT014'] ? widgetLanguageContent['WMT014'] : ''}
								</Label>
								<select
									className='form-control'
									id='seelctHost'
									onChange={e => handleOnChange(e, 'meetingHost')}
									value={state.meetingHost}
								>
									<option value=''>{widgetLanguageContent['WMT005'] ? widgetLanguageContent['WMT005'] : ''}</option>
									{orgList &&
										orgList.length > 0 &&
										orgList.map((item, i) => (
											<option value={item['value']} key={i}>
												{item['text']}
											</option>
										))}
								</select>
							</FormGroup>
							{validation.error_meeting_host && <p className='formValidationError'>{validation.error_meeting_host}</p>}

							<FormGroup>
								<Label for='lodgeCountry'>
									{widgetLanguageContent['WMT024'] ? widgetLanguageContent['WMT024'] : ''}
								</Label>
								<select
									className='form-control'
									id='seelctHost'
									onChange={e => handleOnChange(e, 'meetingType')}
									value={state.meetingType}
								>
									<option value=''>{widgetLanguageContent['WMT005'] ? widgetLanguageContent['WMT005'] : ''}</option>
									{meetingTypesData &&
										meetingTypesData.length > 0 &&
										meetingTypesData.map((item, i) => (
											<option value={item['id']} key={item['id']}>
												{item['name']}
											</option>
										))}
								</select>
							</FormGroup>
							{validation.error_meeting_type && <p className='formValidationError'>{validation.error_meeting_type}</p>}

							<FormGroup>
								<Label for='lodgeCountry'>
									{widgetLanguageContent['WMT006'] ? widgetLanguageContent['WMT006'] : ''}
								</Label>
								<Input
									type='text'
									name='meetingName'
									id='meetingName'
									placeholder=''
									onChange={e => handleOnChange(e, 'meetingName')}
									value={state.meetingName}
									className='form-control'
								/>
							</FormGroup>
							{validation.error_meeting_name && <p className='formValidationError'>{validation.error_meeting_name}</p>}
							<FormGroup className='meetingDateTime'>
								<Label for='lodgeCountry'>
									{widgetLanguageContent['WMT007'] ? widgetLanguageContent['WMT007'] : ''}
								</Label>

								<Datetime
									dateFormat='DD MMM. YYYY'
									value={satrtDate}
									timeFormat={true}
									id='dx245762_date'
									isValidDate={valid}
									onChange={onChangeDate}
									inputProps={{
										onKeyDown: e => {
											e.preventDefault();
										}
									}}
								/>
							</FormGroup>

							<FormGroup className='meetingDateTime'>
								<Label for='lodgeCountry'>
									{widgetLanguageContent['WMT008'] ? widgetLanguageContent['WMT008'] : ''}
								</Label>

								<Datetime
									dateFormat='DD MMM. YYYY'
									value={endDate}
									timeFormat={true}
									id='dx245762_date'
									isValidDate={valid}
									onChange={onChangeEndDate}
									inputProps={{
										onKeyDown: e => {
											e.preventDefault();
										}
									}}
								/>
							</FormGroup>

							<Button color='primary' size='sm' className='' onClick={proceedStep2}>
								{widgetLanguageContent['WMT009'] ? widgetLanguageContent['WMT009'] : ''}
							</Button>
						</>
					)}

					{step2 && (
						<>
							<FormGroup>
								<Label for='lodgeCountry'>
									{widgetLanguageContent['WMT011'] ? widgetLanguageContent['WMT011'] : ''}
								</Label>

								<CustomInput
									id='virtualOption'
									label={widgetLanguageContent['WMT012'] ? widgetLanguageContent['WMT012'] : ''}
									onChange={onChangeVirtual}
									checked={virtual}
									type='switch'
								/>
							</FormGroup>

							{showHideVirtualOptions && (
								<>
									<FormGroup>
										<Label for='lodgeCountry'>
											{widgetLanguageContent['WMT013'] ? widgetLanguageContent['WMT013'] : ''}
										</Label>
										<Input
											type='text'
											name='eventUrl'
											id='eventUrl'
											placeholder=''
											onChange={e => handleOnChange(e, 'eventUrl')}
											value={state.eventUrl}
											className='form-control'
										/>
									</FormGroup>
									{validation.error_event_url && <p className='formValidationError'>{validation.error_event_url}</p>}
									<FormGroup>
										<Label for='lodgeCountry'>
											{widgetLanguageContent['WMT015'] ? widgetLanguageContent['WMT015'] : ''}
										</Label>
										<Input
											type='text'
											name='eventId'
											id='eventId'
											placeholder=''
											onChange={e => handleOnChange(e, 'eventId')}
											value={state.eventId}
											className='form-control'
										/>
									</FormGroup>

									<FormGroup>
										<Label for='lodgeCountry'>
											{widgetLanguageContent['WMT016'] ? widgetLanguageContent['WMT016'] : ''}
										</Label>
										<Input
											type='text'
											name='eventPasscode'
											id='eventPasscode'
											placeholder=''
											value={state.eventPasscode}
											onChange={e => handleOnChange(e, 'eventPasscode')}
											className='form-control'
										/>
									</FormGroup>
								</>
							)}
							<FormGroup>
								<CustomInput
									id='shareMasons'
									label={widgetLanguageContent['WMT017'] ? widgetLanguageContent['WMT017'] : ''}
									onChange={() => setSharing(!sharing)}
									checked={sharing}
									type='switch'
								/>
							</FormGroup>
							<FormGroup>
								<CustomInput
									id='allowWebCheckin'
									label={widgetLanguageContent['WMT018'] ? widgetLanguageContent['WMT018'] : ''}
									onChange={() => setWebCheckIn(!webCheckIn)}
									checked={webCheckIn}
									type='switch'
								/>
							</FormGroup>

							<FormGroup>
								<Label for='lodgeCountry'>
									{widgetLanguageContent['WMT019'] ? widgetLanguageContent['WMT019'] : ''}
								</Label>
								<Input type='file' id='uploadCaptureInputFile' accept='.jpg, .jpeg, .png' />
							</FormGroup>
							<FormGroup>
								<Label for='lodgeCountry'>
									{widgetLanguageContent['WMT020'] ? widgetLanguageContent['WMT020'] : ''}
								</Label>

								<Input type='file' id='uploadCaptureInputFile' accept='.pdf' />
							</FormGroup>

							<Button color='primary' size='sm' className='' onClick={goBack}>
								{widgetLanguageContent['WMT010'] ? widgetLanguageContent['WMT010'] : ''}
							</Button>
							<Button color='primary' size='sm' className='' onClick={handleSubmit} style={{ marginLeft: '10px' }}>
								{widgetLanguageContent['WMT021'] ? widgetLanguageContent['WMT021'] : ''}
							</Button>
							{showHideSubmitLoader && (
								<img
									className='rounded-circle avatar-xl'
									src={loader_image}
									alt='img'
									width='100px'
									id={''}
									style={{ marginLeft: '10px', position: 'absolute', marginTop: '-5px' }}
								/>
							)}
						</>
					)}
				</div>
			</CardBody>
		</Card>
	);
};

export default AddNewMeeting;
