/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardBody, Input } from 'reactstrap'; // eslint-disable-next-line
import avatar_img from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, getLanguagePackUrl } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
import UserProfileTopSection from './UserProfileTopSection';
import UserProfileContentSection from './UserProfileContentSection';
import './profile_modal.css';
const UserProfileModal = ({ showHide, className, onClick, count, profileId = 564743139 }) => {
	const [showHideLoader, setShowHideLoader] = useState(false); // eslint-disable-next-line
	const [data, setData] = useState([]); // eslint-disable-next-line
	const [loginHistoryData, setLoginHistoryData] = useState([]); // eslint-disable-next-line
	const [avatar, setAvatar] = useState(avatar_img); // eslint-disable-next-line
	const [pageLanguageContent, setPageLanguageContent] = useState([]); // eslint-disable-next-line
	const [showCardSection, setShowCardSection] = useState(false); // eslint-disable-next-line
	const [currentActiveTab, setCurrentActiveTab] = useState('1');
	const [showHideModalContent, setShowHideModalConten] = useState(false);
	useEffect(() => {
		if (showHide) {
			var id = profileId;
			setShowHideLoader(true);
			var checkData = JSON.parse(localStorage.getItem('idiom'));
			if (checkData === null || checkData === undefined || checkData === '') {
				var getLanguageURL = getLanguagePackUrl();
				copiriPortalRequest(getLanguageURL, config.app_version, 'get')
					.then(response => {
						if (response.data.result.PRL) {
							setPageLanguageContent(response.data.result.PRL);
							localStorage.setItem('idiom', JSON.stringify(response.data.result));
						}
						getUserProfileData(id);
					})
					.catch(error => {
						setShowHideLoader(false);
					});
			} else {
				if (checkData.PRL) {
					setPageLanguageContent(checkData.PRL);
				}
				getUserProfileData(id);
			}
		}
		// eslint-disable-next-line
	}, [showHide]);

	function getUserProfileData(id) {
		setShowHideModalConten(false);
		copiriPortalRequest('/users/' + id + '?format=detailed', config.app_version, 'get', data)
			.then(response => {
				setData(response.data.result);
				setShowHideModalConten(true);
				if (response.data.result.partial) {
					setShowCardSection(true);
				} else {
					setShowCardSection(false);
				}
				setLoginHistoryData(response.data.result.login_history);
				setShowHideLoader(false);
			})
			.catch(error => {
				setData([]);
				setShowCardSection(false);
				setShowHideLoader(false);
				setShowHideModalConten(false);
			});
	}

	/* Function to delete user*/
	// eslint-disable-next-line
	const deleteUser = e => {
		e.preventDefault();
	};
	// eslint-disable-next-line
	const updateData = (id, inputFields) => {
		// eslint-disable-next-line
		const { first_name, last_name, middle_name, name_full, name_pref, suffix_id, second_last_name } = inputFields;
		let spouse = {};
		spouse['first_name'] = inputFields['spouse_first_name'];
		spouse['middle_name'] = inputFields['spouse_middle_name'];
		spouse['last_name_one'] = inputFields['spouse_last_name'];
		spouse['name_full'] = (inputFields['spouse_first_name'])+' '+ (inputFields['spouse_last_name']?inputFields['spouse_last_name']:'');
		setData({ ...data, first_name, last_name, middle_name, name_pref, name_full, suffix_id, second_last_name,spouse });
	};
	return (
		<div>
			<Modal
				isOpen={showHide}
				toggle={onClick}
				className=''
				id='profile_modal'
				size='xl'
				aria-labelledby='example-modal-sizes-title-lg'
			>
				{showHideLoader ? (
					<CopiriLoader />
				) : (
					<>
						<ModalHeader toggle={onClick}></ModalHeader>
						<ModalBody className='p-0 grey-bg'>
							{showHideModalContent ? (
								<CardBody className='p-0'>
									<UserProfileTopSection
										data={data}
										pageLanguageContent={pageLanguageContent}
										showCardSection={showCardSection}
									/>
									<UserProfileContentSection
										data={data}
										pageLanguageContent={pageLanguageContent}
										updateData={updateData}
										profileId={profileId}
									/>
								</CardBody>
							) : (
								<CardBody className='pt-2'>
									<Row>
										<Col lg={12} className='text-center'>
											<div className='profile-card-info'>
												<p>
													<strong>{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}</strong>
												</p>
											</div>
										</Col>
									</Row>
								</CardBody>
							)}
						</ModalBody>
						<ModalFooter>
							{showHideModalContent && (
								<Button color='secondary' onClick={onClick}>
									{pageLanguageContent['PRL063'] ? pageLanguageContent['PRL063'] : ''}
								</Button>
							)}
						</ModalFooter>
					</>
				)}
			</Modal>
		</div>
	);
};
export default UserProfileModal;
