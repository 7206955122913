/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from 'reactstrap';
import FormGroupInput from '../common/FormGroupInput';
import { copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
import SpouseInformation from '../profile/SpouseInformation';
const EditProfileNameModal = props => {// eslint-disable-next-line
	const { data, inputField, setInputField, type, pageLanguageContent, copiriGenderList } = props;
	const [sufixesList, setSufixesList] = useState([]);
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [validation, setValidation] = useState({
		error_first_name: '',
		error_last_name: '',
		error_middle_name: '',
		error_name_pref: '',
		error_name_suffix: ''
	});

	const [checkChangeData, setCheckChangeData] = useState({
		first_name: false,
		last_name: false,
		middle_name: false,
		name_pref: false,
		suffix_id: false
	});

	useEffect(() => {
		if (data) {
			let initialsErrors = { ...validation };
			setValidation(initialsErrors);
			const { first_name, last_name, middle_name, name_full, name_pref, suffix_id, suffix, second_last_name,gender,gender_id } = data;
			setInputField({
				...inputField,
				first_name,
				last_name,
				middle_name,
				name_pref,
				name_full,
				suffix_id,
				suffix,
				second_last_name,
				gender,
				gender_id
			});
			getSuffixes();
		}
		// eslint-disable-next-line
	}, [data, setValidation, setInputField,type]);

	const inputsHandler = e => {
		setInputField({ ...inputField, [e.target.name]: e.target.value });
		setCheckChangeData({ ...checkChangeData, [e.target.name]: true });
	};

	function getSuffixes() {
		copiriPortalRequest('/canon/suffixes', config.app_version, 'get', data)
			.then(response => {
				if (response.data.length > 0) {
					setSufixesList(response.data);
				}
			})
			.catch(error => {});
	}

	function updateProfileName() {
		let errors = { ...validation };
		var errorCount = 0;
		//first name validation
		if (!inputField.first_name.trim()) {
			errors.error_first_name = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_first_name = '';
		}
		//name preference validation
		/*if (!inputField.name_pref.trim()) {
			errors.error_name_pref = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_name_pref = '';
		}

		//middle name validation
		if (!inputField.middle_name.trim()) {
			errors.error_middle_name = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_middle_name = '';
		}*/
		//last name validation
		if (!inputField.last_name.trim()) {
			errors.error_last_name = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_last_name = '';
		}

		/*if (!inputField.suffix_id) {
			errors.error_suffix = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_suffix = '';
		}*/

		//inputField.suffix
		setValidation(errors);
		if (errorCount <= 0) {
			setDisableSubmitButton(true);
			inputField['name_full'] =
				inputField['first_name'] + ' ' + inputField['middle_name'] + ' ' + inputField['last_name'];
			var sendFormData = {};

			if (checkChangeData['name_pref']) {
				sendFormData['preferred_name'] = inputField['name_pref'];
			}

			if (checkChangeData['first_name']) {
				sendFormData['first_name'] = inputField['first_name'];
			}

			if (checkChangeData['middle_name']) {
				sendFormData['middle_name'] = inputField['middle_name'];
			}

			if (checkChangeData['last_name']) {
				sendFormData['last_name'] = inputField['last_name'];
			}

			if (checkChangeData['suffix']) {
				sendFormData['suffix_id'] = inputField['suffix'];
			}
			if (inputField['gender_id']) {
				sendFormData['gender_id'] = inputField['gender_id'];
			}
			copiriPortalRequest('/users/' + data['id'], config.app_version, 'patch', sendFormData)
			.then(response => {
				if (response.status && response.status === 200) {
					setDisableSubmitButton(false);
					toast.success(response?.data?.status);
					props.onClick();
					props.updateModal(type, data['id'], inputField);					
				}
			})
			.catch(error => {setDisableSubmitButton(false);});
		}
	}

	return (
		<>
			<Modal isOpen={props.showHide} toggle={props.onClick}>
				<ModalHeader
					toggle={props.onClick}
					style={{ backgroundColor: '#337ab7', border: '#337ab7' }}
					className='modalHeader'
				>
					{
						type && type==='edit_spouse' ? 
						<>{pageLanguageContent['PRL146'] ? pageLanguageContent['PRL146'] : ''}</>
					: 	<>{pageLanguageContent['PRL041'] ? pageLanguageContent['PRL041'] : ''}</>
					}
				</ModalHeader>
				<ModalBody>
				{
					type && type==='edit_spouse' ?
					<SpouseInformation userProfileData={data}
						pageLanguageContent={pageLanguageContent}
						copiriGenderList={copiriGenderList} 
						closeModal={props.onClick} 
						updateModal ={props.updateModal}
						type ={type}
						datainputField={inputField}
						setInputField={setInputField}
						inputField={inputField}

						/>
					:
					<Row>
						<Col lg={12}>
							<FormGroupInput
								id=''
								label=''
								type='text'
								value={
									inputField.first_name +
									(inputField.first_name?' ':'') +
									(inputField.name_pref?'('+inputField.name_pref+')':'')+
									(inputField.name_pref?' ':'')+
									(inputField.middle_name?inputField.middle_name:'') +
									( inputField.middle_name?' ':'') +
									(inputField.last_name?inputField.last_name:'') +
									(inputField.last_name?' ':'') +
									(inputField.second_last_name?inputField.second_last_name:'') +
									(inputField.second_last_name?' ':'') +
									(inputField.suffix?inputField.suffix:'')
								}
								disabled
							/>
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='first_name'
								label={pageLanguageContent['PRL102'] ? pageLanguageContent['PRL102'] : ''}
								name='first_name'
								type='text'
								onChange={inputsHandler}
								value={inputField.first_name}
							/>
							{validation.error_first_name && <p className='formValidationError'>{validation.error_first_name}</p>}
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='name_pref'
								label={pageLanguageContent['PRL103'] ? pageLanguageContent['PRL103'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.name_pref}
								name='name_pref'
							/>
							{validation.error_name_pref && <p className='formValidationError'>{validation.error_name_pref}</p>}
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='middle_name'
								label={pageLanguageContent['PRL104'] ? pageLanguageContent['PRL104'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.middle_name}
								name='middle_name'
							/>
							{validation.error_middle_name && <p className='formValidationError'>{validation.error_middle_name}</p>}
						</Col>
						<Col lg={6}>
						</Col>
						<Col lg={6}>
							<FormGroupInput
								id='last_name'
								label={pageLanguageContent['PRL105'] ? pageLanguageContent['PRL105'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.last_name}
								name='last_name'
							/>
							{validation.error_last_name && <p className='formValidationError'>{validation.error_last_name}</p>}
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='second_last_name'
								label={pageLanguageContent['PRL106'] ? pageLanguageContent['PRL106'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.second_last_name}
								name='second_last_name'
							/>
						</Col>
						{/*<Col lg={6}>
							<FormGroup>
								<Label for='gender_id'>{pageLanguageContent['PRL144'] ? pageLanguageContent['PRL144'] : ''}</Label>
								<select
									id='gender'
									label={pageLanguageContent['PRL144'] ? pageLanguageContent['PRL144'] : ''}
									name='gender_id'
									className='form-control'
									onChange={inputsHandler}
									value={inputField.gender_id}
								>
									<option value=''>{pageLanguageContent['PRL100'] ? pageLanguageContent['PRL100'] : ''}</option>
									{copiriGenderList.length > 0 &&
										copiriGenderList.map((item, i) => (
											<option value={item.id} key={item.id}>
												{item.title}
											</option>
										))}
								</select>
							</FormGroup>
						</Col>*/}

						<Col lg={6}>
							<FormGroup>
								<Label for='suffix'>{pageLanguageContent['PRL107'] ? pageLanguageContent['PRL107'] : ''}</Label>
								<select
									id='suffix'
									label={pageLanguageContent['PRL107'] ? pageLanguageContent['PRL107'] : ''}
									type='text'
									onChange={inputsHandler}
									value={inputField.suffix}
									name='suffix'
									className='form-control'
								>
									<option value=''>{pageLanguageContent['PRL100'] ? pageLanguageContent['PRL100'] : ''}</option>
									{sufixesList.length > 0 &&
										sufixesList.map((item, i) => (
											<option value={item.name} key={item.id}>
												{item.name}
											</option>
										))}
								</select>
							</FormGroup>
							{validation.error_suffix && <p className='formValidationError'>{validation.error_suffix}</p>}
						</Col>
					</Row>
				}
				</ModalBody>
				<ModalFooter>
					{
						type && type==='edit_name' && 
						<Button color='primary' onClick={updateProfileName} disabled={disableSubmitButton}>
							{pageLanguageContent['PRL101'] ? pageLanguageContent['PRL101'] : ''}
						</Button>
					}
				</ModalFooter>
			</Modal>
		</>
	);
};
export default EditProfileNameModal;
