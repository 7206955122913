/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from 'reactstrap';
import { copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
import Datetime from 'react-datetime';
import './profile_modal.css';
import {genPageData} from '../langconfig/langconfig'; // eslint-disable-next-line
const EditProfileDateModal = props => {
	const { data, type, pageLanguageContent,dateGenderType,copiriGenderList, inputField, setInputField} = props;
	const [date, setDate] = useState();
	const [validation, setValidation] = useState({
		error_date: '',
		error_gender: '',
	});
	const [disableSubmitButton,setDisableSubmitButton] = useState(false);
	useEffect(() => {
		if (data) {
			let initialsErrors = { ...validation };
			if (data.birthdate) {
				var calDate = data.birthdate.split('-');
				var mydate = new Date(calDate['0'] + '-' + calDate['1'] + '-' + calDate['2']);
				const month = mydate.toLocaleString('default', { month: 'short' });
				let user_date = calDate['2'] +' ' + month +'. '+ calDate['0'];
				setDate(user_date);
			}
			setValidation(initialsErrors);
		}
		// eslint-disable-next-line
	}, [props]);

	/* function to check the value of date input box  */
	const onChangeDate = event => {
		setDate(new Date(event));
	};

	const inputsHandler = e => {
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};

	function updateProfileName() {
		let errors = { ...validation };
		var errorCount = 0;
		//first name validation
		if (dateGenderType && dateGenderType==='dob'){
			if (!date) {
				errors.error_date = pageLanguageContent['PRL026'];
				errorCount++;
			} else {
				errors.error_date = '';
			}
		}
		if (dateGenderType && dateGenderType==='gender'){
			if (!inputField.gender_id) {
				errors.error_gender = pageLanguageContent['PRL026'];
				errorCount++;
			} else {
				errors.error_gender = '';
			}
		}
		setValidation(errors);
		if (errorCount <= 0) {
			setDisableSubmitButton(true);
			var sendFormData = {};
			if (dateGenderType && dateGenderType==='dob'){
				if (date) {
					let mydate = new Date(date);
					let dob = mydate.getFullYear() + '-' + (mydate.getMonth() + 1) + '-' + mydate.getDate();
					sendFormData['birthdate'] = dob;
				}
			}
			if (dateGenderType && dateGenderType==='gender'){
				if (inputField['gender_id']) {
					sendFormData['gender_id'] = inputField['gender_id'];
				}
			}

			copiriPortalRequest('/users/' + data['id'], config.app_version, 'patch', sendFormData)
				.then(response => {
					setDisableSubmitButton(false);
					if (response.status && response.status === 200) {
						toast.success(genPageData['GENDUS'] ? genPageData['GENDUS'] : '');
						props.onClick();
						props.updateModal(type, data['id'], inputField,date);
					}
				})
				.catch(error => {setDisableSubmitButton(false);});
		}
	}

	function renderInput(props, openCalendar, closeCalendar) {
		return (
	      <div>
	        <input {...props} />
	        <i className="far fa-calendar" style={{position:'absolute',right:'10px',top:'10px'}} onClick={openCalendar}></i>
	      </div>
	    );
	}

	return (
		<>
			<Modal isOpen={props.showHide} toggle={props.onClick}>
				<ModalHeader
					toggle={props.onClick}
					style={{ backgroundColor: '#337ab7', border: '#337ab7' }}
					className='modalHeader'
				>
					{pageLanguageContent['PRL041'] ? pageLanguageContent['PRL041'] : ''}
				</ModalHeader>
				<ModalBody>
					{ dateGenderType && dateGenderType==='dob' &&
						<Row className="userProfileDateBirth">
							<Col lg={12}>
								<FormGroup>
									<Label for='dob'>{pageLanguageContent['PRL145'] ? pageLanguageContent['PRL145'] : ''}</Label>
									<Datetime
										dateFormat='DD MMM. YYYY'
										timeFormat={false}
										value={date}
										className={'edit_user_dob'}
										onChange={onChangeDate}
										renderInput={renderInput}
										closeOnSelect={true}
									/>
									
								</FormGroup>
								{validation.error_date && <p className='formValidationError'>{validation.error_date}</p>}
							</Col>
						</Row>
					}
					{ dateGenderType && dateGenderType==='gender' &&
						<Row className="userProfileDateBirth">
							<Col lg={12}>
								<FormGroup>
									<Label for='gender'>{pageLanguageContent['PRL144'] ? pageLanguageContent['PRL144'] : ''}</Label>
									<select
										id='gender'
										label={pageLanguageContent['PRL144'] ? pageLanguageContent['PRL144'] : ''}
										name='gender_id'
										className='form-control'
										onChange={inputsHandler}
										value={inputField.gender_id}
									>
										<option value=''>{pageLanguageContent['PRL100'] ? pageLanguageContent['PRL100'] : ''}</option>
										{copiriGenderList.length > 0 &&
											copiriGenderList.map((item, i) => (
												<option value={item.id} key={item.id}>
													{item.title}
												</option>
											))}
									</select>
								</FormGroup>
								{validation.error_gender && <p className='formValidationError'>{validation.error_gender}</p>}
							</Col>
						</Row>
					}
				</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={updateProfileName} disabled={disableSubmitButton}>
						{pageLanguageContent['PRL101'] ? pageLanguageContent['PRL101'] : ''}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
export default EditProfileDateModal;
