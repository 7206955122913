import React, { useState, useEffect } from 'react'; // eslint-disable-next-line
import { Card, CardBody, Col, Row, FormGroup, Label, Button } from 'reactstrap';
import FormGroupInput from '../common/FormGroupInput'; // eslint-disable-next-line
import { checkValidation, copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader'; // eslint-disable-next-line
const SpouseInformation = props => {
	const { userProfileData, pageLanguageContent, copiriGenderList,closeModal,updateModal,type,inputField, setInputField, } = props;
	const [sufixesList, setSufixesList] = useState([]);
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	/* set and get varaible for input errors */
	const [validation, setValidation] = useState({
		error_spouse_first_name: '',
		error_spouse_middle_name: '',
		error_spouse_last_name: '',
		error_spouse_suffix: '',
		error_spouse_gender: '',
		error_spouse_preferred_name: '',
		error_spouse_second_last_name: ''
	});
	let langData = JSON.parse(localStorage.getItem('idiom'));
	let genItemData = langData?.GEN;
	useEffect(() => {
		if (userProfileData) {
			let spouse_first_name = userProfileData?.spouse?.first_name;
			let spouse_last_name = userProfileData?.spouse?.last_name_one;
			let spouse_second_last_name = userProfileData?.spouse?.last_name_two;
			let spouse_middle_name = userProfileData?.spouse?.middle_name;
			let spouse_preferred_name = userProfileData?.spouse?.preferred_name;
			let spouse_suffix = userProfileData?.spouse?.suffix_id;
			let spouse_gender = userProfileData?.spouse?.gender;
			let spouse_gender_id = userProfileData?.spouse?.gender_id;
			setInputField({
				...inputField,
				spouse_first_name,
				spouse_last_name,
				spouse_second_last_name,
				spouse_middle_name,
				spouse_preferred_name,
				spouse_suffix,
				spouse_gender,
				spouse_gender_id
			});
			getSuffixes();
		}
		// eslint-disable-next-line
	}, [userProfileData, setValidation, setInputField,type]);

	/* function to set the input email field data */
	const inputsHandler = name => e => {
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};

	/* fuction to call API to get suffixes list */
	function getSuffixes() {
		copiriPortalRequest('/canon/suffixes', config.app_version, 'get')
			.then(response => {
				if (response.data.length > 0) {
					setSufixesList(response.data);
				}
			})
			.catch(error => {});
	}

	function sameLastName() {
		let spouse_last_name = userProfileData?.last_name;
		setInputField({ ...inputField, spouse_last_name });
	}

	/* check validation and submit input data */
	// eslint-disable-next-line
	function submitData() {
		let errors = { ...validation };
		var errorCount = 0;
		if (!inputField.spouse_first_name) {
			// spouse first name is empty
			errors.error_spouse_first_name = pageLanguageContent['PRL026'] ? pageLanguageContent['PRL026'] : '';
			errorCount++;
		} else {
			errors.error_spouse_first_name = '';
		}

		/*if (!inputField.spouse_middle_name.trim()) { // spouse middle name is empty
			errors.error_spouse_middle_name = pageLanguageContent['PRL026']?pageLanguageContent['PRL026']:'';
			errorCount++;
		} else {
			errors.error_spouse_middle_name = '';
		}

		//spouse_preferred_name
		if (!inputField.spouse_preferred_name.trim()) { // spouse preferred name is empty
			errors.error_spouse_preferred_name = pageLanguageContent['PRL026']?pageLanguageContent['PRL026']:'';
			errorCount++;
		} else {
			errors.error_spouse_preferred_name = '';
		}*/

		if (!inputField.spouse_last_name) {
			// spouse last name is empty
			errors.error_spouse_last_name = pageLanguageContent['PRL026'] ? pageLanguageContent['PRL026'] : '';
			errorCount++;
		} else {
			errors.error_spouse_last_name = '';
		}

		/*if (!inputField.spouse_suffix) { // spouse suffix is empty
			errors.error_spouse_suffix = pageLanguageContent['PRL026']?pageLanguageContent['PRL026']:'';
			errorCount++;
		} else {
			errors.error_spouse_suffix = '';
		}

		if (!inputField.spouse_gender) { // spouse gender is empty
			errors.error_spouse_gender = pageLanguageContent['PRL026']?pageLanguageContent['PRL026']:'';
			errorCount++;
		} else {
			errors.error_spouse_gender = '';
		}

		if (!inputField.spouse_second_last_name.trim()) { // spouse second last name is empty
			errors.error_spouse_second_last_name = pageLanguageContent['PRL026']?pageLanguageContent['PRL026']:'';
			errorCount++;
		} else {
			errors.error_spouse_second_last_name = '';
		}*/
		setValidation(errors);
		if (errorCount <= 0) {
			setDisableSubmitButton(true);
			var sendFormData = {};
			var spouseFormData = {};
			spouseFormData['first_name'] = inputField.spouse_first_name;
			spouseFormData['middle_name'] = inputField.spouse_middle_name;
			spouseFormData['preferred_name'] = inputField.spouse_preferred_name;
			spouseFormData['last_name_one'] = inputField.spouse_last_name;
			spouseFormData['last_name_two'] = inputField.spouse_second_last_name;
			spouseFormData['suffix_id'] = inputField.spouse_suffix;
			spouseFormData['gender_id'] = inputField.spouse_gender_id;
			sendFormData['spouse'] = spouseFormData;
			var userId = userProfileData['id'];
			var url = '/users/' + userId;
			copiriPortalRequest(url, config.app_version, 'patch', sendFormData)
				.then(response => {
					setDisableSubmitButton(false);
				    toast.success(response?.data?.status);
					closeModal();
					updateModal(type, userProfileData['id'], inputField)					
				})
				.catch(error => {
					setDisableSubmitButton(false);
					toast.error(error?.response?.data?.result);
				});
		}
	}

	return (
		<>
			<Row>
				<Col lg={6}>
					<FormGroupInput
						id='spouse_first_name'
						label={pageLanguageContent['PRL147'] ? pageLanguageContent['PRL147'] : ''}
						type='text'
						onChange={inputsHandler('spouse_first_name')}
						value={inputField.spouse_first_name}
						name='spouse_first_name'
					/>
					{validation.error_spouse_first_name && (
						<p className='formValidationError'>{validation.error_spouse_first_name}</p>
					)}
				</Col>
				<Col lg={6}>
					<FormGroupInput
						id='spouse_preffered_name'
						label={pageLanguageContent['PRL103'] ? pageLanguageContent['PRL103'] : ''}
						type='text'
						onChange={inputsHandler('spouse_preferred_name')}
						value={inputField.spouse_preferred_name}
						name='spouse_preferred_name'
					/>
					{validation.error_spouse_preferred_name && (
						<p className='formValidationError'>{validation.error_spouse_preferred_name}</p>
					)}
				</Col>
				<Col lg={6}>
					<FormGroupInput
						id='spouse_middle_name'
						label={pageLanguageContent['PRL148'] ? pageLanguageContent['PRL148'] : ''}
						type='text'
						onChange={inputsHandler('spouse_middle_name')}
						value={inputField.spouse_middle_name}
						name='spouse_middle_name'
					/>
					{validation.error_spouse_middle_name && (
						<p className='formValidationError'>{validation.error_spouse_middle_name}</p>
					)}
				</Col>	
				<Col lg={6}>			
				</Col>				
				<Col lg={6}>
					<FormGroup>
						<Label for='last_name'>{pageLanguageContent['PRL149'] ? pageLanguageContent['PRL149'] : ''}</Label>
						<span onClick={sameLastName} className='same_last_name'>
							{pageLanguageContent['PRL151'] ? pageLanguageContent['PRL151'] : ''}
						</span>
						<input
							type='text'
							onChange={inputsHandler('spouse_last_name')}
							value={inputField.spouse_last_name}
							name='spouse_last_name'
							className='form-control'
						/>
					</FormGroup>
					{validation.error_spouse_last_name && (
						<p className='formValidationError'>{validation.error_spouse_last_name}</p>
					)}
				</Col>
				<Col lg={6}>
					<FormGroupInput
						id='spouse_second_last_name'
						label={pageLanguageContent['PRL150'] ? pageLanguageContent['PRL150'] : ''}
						type='text'
						onChange={inputsHandler('spouse_second_last_name')}
						value={inputField.spouse_second_last_name}
						name='spouse_second_last_name'
					/>
					{validation.error_spouse_second_last_name && (
						<p className='formValidationError'>{validation.error_spouse_second_last_name}</p>
					)}
				</Col>
				<Col lg={6}>
					<FormGroup>
						<Label for='suffix'>{pageLanguageContent['PRL107'] ? pageLanguageContent['PRL107'] : ''}</Label>
						<select
							id='suffix'
							label={pageLanguageContent['PRL107'] ? pageLanguageContent['PRL107'] : ''}
							className='form-control'
							onChange={inputsHandler('spouse_suffix')}
							value={inputField.spouse_suffix}
							name='spouse_suffix'
						>
							<option value=''>{pageLanguageContent['PRL100'] ? pageLanguageContent['PRL100'] : ''}</option>
							{sufixesList.length > 0 &&
								sufixesList.map((item, i) => (
									<option value={item.id} key={item.id}>
										{item.name}
									</option>
								))}
						</select>
					</FormGroup>
					{validation.error_spouse_suffix && <p className='formValidationError'>{validation.error_spouse_suffix}</p>}
				</Col>
				<Col lg={6}>
					<FormGroup>
						<Label for='spouse_gender_id'>Gender</Label>
						<select
							id='gender'
							label={pageLanguageContent['PRL144'] ? pageLanguageContent['PRL144'] : ''}
							className='form-control'
							onChange={inputsHandler('spouse_gender_id')}
							value={inputField.spouse_gender_id}
							name='spouse_gender_id'
						>
							<option value=''>{pageLanguageContent['PRL100'] ? pageLanguageContent['PRL100'] : ''}</option>
							{copiriGenderList.length > 0 &&
								copiriGenderList.map((item, i) => (
									<option value={item.id} key={item.id}>
										{item.title}
									</option>
								))}
						</select>
					</FormGroup>
					{validation.error_spouse_gender && <p className='formValidationError'>{validation.error_spouse_gender}</p>}
				</Col>
				<Col lg={4}>
					<Button color='primary' className='w-100 py-2 mt-3' onClick={submitData} disabled={disableSubmitButton}>
						{genItemData['GENSMT'] ? genItemData['GENSMT'] : ''}
					</Button>
				</Col>
			</Row>
		</>
	);
};
export default SpouseInformation;
