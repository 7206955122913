import React, { useEffect } from 'react';

function ColumnChart(props) {
	const divId = props.chartId;
	const dataProvider = props.data;
	const divHeight = props.height ? props.height : '45px'; 
	const loadAmCharts = () => {
		Promise.all([
			import('@amcharts/amcharts4/core'),
			import('@amcharts/amcharts4/charts'),
			import('@amcharts/amcharts4/themes/animated')
		]).then(modules => {
			let am4core = modules[0];
			let am4charts = modules[1];
			let am4themes_animated = modules[2].default;
			am4core.useTheme(am4themes_animated);
			am4core.options.autoDispose = true;
			am4core.addLicense('CH259299157');
			var chart = am4core.create(divId, am4charts.XYChart);
			chart.data = dataProvider;
			// Create axes

			let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = "date";
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.minGridDistance = 30;
			categoryAxis.renderer.grid.template.disabled = true;
			categoryAxis.renderer.labels.template.disabled = true;
			categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
			  if (target.dataItem && target.dataItem.index == 2) {
			    return dy + 25;
			  }
			  return dy;
			});

			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.grid.template.disabled = true;
			valueAxis.renderer.labels.template.disabled = true;
			// Create series
			let series = chart.series.push(new am4charts.ColumnSeries());

			series.dataFields.valueY = "amount";
			series.dataFields.categoryX = "date";
			series.columns.template.tooltipText = "{categoryX}: [bold]{amount}[/]";
			series.columns.template.fillOpacity = .8;
			series.fill = "#0b38b6";
			series.stroke = "#0b38b6";
			let columnTemplate = series.columns.template;
			columnTemplate.strokeWidth = .2;
			columnTemplate.strokeOpacity = .2;
			chart.current = chart;
		});
	};

	useEffect(() => {
		let chartData = props.data;
		let sum = chartData.reduce((n, {amount}) => n + amount, 0)
		if (sum && sum >0) {
			loadAmCharts();
		}
		// eslint-disable-next-line
	}, [props]);

	return (
			<div id={divId} style={{ left: '-16px', height: divHeight, minHeight: divHeight,position:'absolute',bottom:'-15px',zIndex:'999' }}></div>
	);
}
export default ColumnChart;