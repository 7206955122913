/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardBody, Input } from 'reactstrap'; // eslint-disable-next-line
import avatar_img from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
import UserMemberShipInformationAccordion from './accordion/UserMemberShipInformationAccordion';
const UserMemebrShipInformation = ({ data, pageLanguageContent, profileId, showMemberShipTabData }) => {
	const [membershipData, setMembershipData] = useState([]);
	const [showData, setShowData] = useState(false);
	useEffect(() => {
		if (showMemberShipTabData && membershipData.length <= 0) {
			getMemebrShipInformationData(profileId);
			localStorage.setItem('active_profile_id', profileId);
		}
		// eslint-disable-next-line
	}, [showMemberShipTabData]);

	function getMemebrShipInformationData(id) {
		setShowData(false);
		//id=564748558;
		copiriPortalRequest('/users/' + id + '/memberships', config.app_version, 'get', data)
			.then(response => {
				var arr = [];
				for (const key in response.data.memberships) {
					arr.push(response.data.memberships[key]);
				}
				parentChildGrouping(arr);
				//setMembershipData(arr);
				setShowData(true);
			})
			.catch(error => {
				setMembershipData([]);
				setShowData(true);
			});
	}

	function parentChildGrouping(arr) {
		setMembershipData([]);
		var parentArr = [];
		var childArr = []; // eslint-disable-next-line
		var combinedArr = [];
		for (var i = 0; i < arr.length; i++) {
			if (arr[i]['parent_id']) {
				childArr.push(arr[i]);
			} else {
				parentArr.push(arr[i]);
			}
		}

		for (var k = 0; k < parentArr.length; k++) {
			combinedArr.push(parentArr[k]);
			for (var m = 0; m < childArr.length; m++) {
				if (childArr[m]['parent_id'] === parentArr[k]['id']) {
					combinedArr.push(childArr[m]);
				}
			}
		}
		if (combinedArr.length > 0) {
			setMembershipData(combinedArr);
		}
	}

	return (
		<>
			<fieldset className='border p-2'>
				<legend className='w-auto'>{pageLanguageContent['PRL080'] ? pageLanguageContent['PRL080'] : ''}</legend>
				{showData ? (
					<>
						{membershipData && membershipData.length > 0 ? (
							<UserMemberShipInformationAccordion
								membershipData={membershipData}
								showData={showData}
								data={data}
								pageLanguageContent={pageLanguageContent}
								profileId={profileId}
							/>
						) : (
							<p>{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}</p>
						)}
					</>
				) : (
					<CopiriLoader />
				)}
			</fieldset>
		</>
	);
};
export default UserMemebrShipInformation;
