/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react'; // eslint-disable-next-line
// eslint-disable-next-line
import { copiriAxiosPost, copiriAxiosGet, checkValidation, copiriPortalRequest } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FormGroupInput from '../../common/FormGroupInput';
import image_loader from '../../../assets/img/loader/small_loader.gif'; // eslint-disable-next-line
import {genPageData} from '../../langconfig/langconfig'; // eslint-disable-next-line
const EditLodgeNameModal = props => {
	const { lodgeData, pageLanguageContent } = props;
	const [checkDataChange, setCheckDataChange] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [formattedName, setFormattedName] = useState(lodgeData['name']);
	const [disabledNumberField, setDisabledNumberField] = useState(false);
	const [inputField, setInputField] = useState({
		name: lodgeData['name'],
		lodge_number: '',
		u_d: ''
	});

	/* input validation constants*/
	const [validation, setValidation] = useState({
		error_name: '',
		error_number: ''
	});

	useEffect(() => {
		if (props.showHideModal) {
			const { name, provisional } = lodgeData;
			var u_d = '';
			if (provisional) {
				setDisabledNumberField(true);
				u_d = 'yes';
			} else {
				setDisabledNumberField(false);
				u_d = 'no';
			}
			setInputField({ ...inputField, name, u_d });
		}
		// eslint-disable-next-line
	}, [props.showHideModal]);

	/* function to get the input data */
	const inputsHandler = (e, type) => {
		setCheckDataChange(true);
		//setInputField( {[e.target.name]: e.target.value} )
		setInputField({ ...inputField, [e.target.name]: e.target.value });
		if (type === 'name') {
			if (inputField['u_d'] && inputField['u_d'] === 'yes') {
				format_org_name(e.target.value, inputField['lodge_number'], true);
			} else {
				format_org_name(e.target.value, inputField['lodge_number'], false);
			}
		}

		if (type === 'number') {
			if (inputField['u_d'] && inputField['u_d'] === 'yes') {
				format_org_name(inputField['name'], e.target.value, true);
			} else {
				format_org_name(inputField['name'], e.target.value, false);
			}
		}
		if (type === 'ud') {
			if (e.target.value === 'yes') {
				setDisabledNumberField(true);
				format_org_name(inputField['name'], inputField['lodge_number'], true);
			} else {
				setDisabledNumberField(false);
				format_org_name(inputField['name'], inputField['lodge_number'], false);
			}
		}
	};

	/* function to format the organization name */
	function format_org_name(name, number, provisional) {
		// Default our response
		let formatted = '';

		// Clean up the name string
		if (name) name = name.trim();
		// Clean up the number string
		if (number) number = number.trim();

		// If we don't have a name or number, there's nothing to do here.
		if (!name && !number) return formatted;

		// If we don't have a name AND the Lodge is provisional... the number
		// doesn't matter, so there's nothing to do here.
		if (!name && provisional) return formatted;

		// Start the response.  If we have a name, and it includes the word "Lodge"
		// or a known translation, use it as-is.
		if (
			(name && name.indexOf('Lodge') >= 0) ||
			name.indexOf('Loge') >= 0 ||
			name.indexOf('Loja') >= 0 ||
			name.indexOf('Logia') >= 0
		)
			formatted = name;
		// Otherwise, add it along with the word "Lodge".
		// NOTE :: for empty names, just add "Lodge" without the space.
		else formatted = (name ? name + ' ' : '') + 'Lodge';

		// If the "Provisional" box was checked, overwrite the "Number" box.
		if (provisional === true) formatted += ', U.D.';
		// Otherwise, add whatever we have to the return value
		// Note that if the name was empty on entry, it is now "Lodge".
		else formatted += number ? ' No. ' + number : '';

		setFormattedName(formatted);
	}

	/* function to update the data */
	function updateData() {
		let errors = { ...validation };
		var errorCount = 0;
		var checkError = checkValidation(inputField['lodge_number'], 'phone');
		if (!inputField.name.trim()) {
			errors.error_name = pageLanguageContent['CLD071'];
			errorCount++;
		} else {
			errors.error_name = '';
		}
		if (inputField.u_d === '' || inputField.u_d === 'no') {
			if (!inputField.lodge_number.trim()) {
				errors.error_number = pageLanguageContent['CLD071'];
				errorCount++;
			} else {
				errors.error_number = '';
			}
		}
		if (inputField.lodge_number.length > 0) {
			if (!checkError) {
				errors.error_number = pageLanguageContent['CLD072'];
				errorCount++;
			} else {
				errors.error_number = '';
			}
		}
		setValidation(errors);
		if (errorCount <= 0) {
			if (checkDataChange) {
				var sendFormData = {};
				var sendData = {};
				inputField['name'] = inputField['name']
					.replace(' Lodge ', '')
					.replace('.', '')
					.replace('Lodge', '')
					.replace('Lodge ', '')
					.replace('No. ', '')
					.replace('No.', '')
					.replace('No', '');
				sendFormData['provisional'] = false;
				sendFormData['name'] = inputField['name'];
				sendFormData['number'] = inputField['lodge_number'];
				sendFormData['nameShort'] = inputField['name'];
				if (inputField['u_d'] && inputField['u_d'] === 'yes') {
					sendFormData['provisional'] = true;
				}

				sendData['value'] = sendFormData;
				setShowLoader(true);
				copiriPortalRequest('/organizations/' + lodgeData['id'], config.app_version, 'patch', sendFormData)
					.then(response => {
						setShowLoader(false);
						if (response.data.status && response.data.status === 'SUCCESS') {
							toast.success(pageLanguageContent['CLD119']);
							props.closeModal();
							props.updateLodgeModal('name', formattedName);
						}
					})
					.catch(error => {
						toast.error(error.response.data.result);
						setShowLoader(false);
					});
			}
		}
	}

	return (
		<div>
			<Modal isOpen={props.showHideModal} toggle={props.closeModal} id='lodge_modal'>
				<ModalHeader toggle={props.closeModal}>
					{pageLanguageContent['CLD131'] ? pageLanguageContent['CLD131'] : ''}
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col lg={12}>
							<FormGroupInput
								id='lodge-name'
								label={pageLanguageContent['CLD012'] ? pageLanguageContent['CLD012'] : ''}
								type='text'
								name='name'
								onChange={e => inputsHandler(e, 'name')}
								value={inputField.name}
							/>
							{validation.error_name && <p className='formValidationError'>{validation.error_name}</p>}
						</Col>
						<Col lg={12}>
							<FormGroupInput
								id='number'
								label={pageLanguageContent['CLD013'] ? pageLanguageContent['CLD013'] : ''}
								type='text'
								name='lodge_number'
								onChange={e => inputsHandler(e, 'number')}
								value={inputField.lodge_number}
								disabled={disabledNumberField}
							/>
							{validation.error_number && <p className='formValidationError'>{validation.error_number}</p>}
						</Col>
						<Col lg={12}>
							<div className='d-flex lodge-top-item'>
								<div className='d-flex lodge-top-content'>
									<h6>{pageLanguageContent['CLD014'] ? pageLanguageContent['CLD014'] : ''}</h6>
									<div className='ladge-radio-btn'>
										<input
											type='radio'
											id='ud_yes'
											name={'u_d'}
											className='mr-1'
											onChange={e => inputsHandler(e, 'ud')}
											value={'yes'}
											defaultChecked={lodgeData['provisional']}
										/>{' '}
										<label htmlFor='ud_yes'>{pageLanguageContent['CLD026'] ? pageLanguageContent['CLD026'] : ''}</label>
										<input
											type='radio'
											id='ud_no'
											name={'u_d'}
											className='mr-1'
											onChange={e => inputsHandler(e, 'ud')}
											value={'no'}
											defaultChecked={!lodgeData['provisional']}
										/>{' '}
										<label htmlFor='ud_no'>{pageLanguageContent['CLD027'] ? pageLanguageContent['CLD027'] : ''}</label>
									</div>
								</div>
							</div>
						</Col>

						<Col lg={12}>
							<FormGroupInput
								id='formattedLodgeName'
								label={pageLanguageContent['CLD117']}
								type='text'
								value={formattedName}
								disabled
							/>
						</Col>
					</Row>

					<Row>
						<Col lg={12}>
							<div className='alert alert-warning' role='alert'>
								<i className='fas fa-exclamation-triangle'></i>{' '}
								<b>{pageLanguageContent['CLD015'] ? pageLanguageContent['CLD015'] : ''}</b>
								<br />
								{pageLanguageContent['CLD016'] ? pageLanguageContent['CLD016'] : ''}{' '}
								<b>
									<i>{pageLanguageContent['CLD017'] ? pageLanguageContent['CLD017'] : ''}</i>
								</b>{' '}
								{pageLanguageContent['CLD018'] ? pageLanguageContent['CLD018'] : ''}.<br />
								<br />
								{pageLanguageContent['CLD019'] ? pageLanguageContent['CLD019'] : ''}{' '}
								<b>
									<i> {pageLanguageContent['CLD020'] ? pageLanguageContent['CLD020'] : ''}</i>
								</b>{' '}
								{pageLanguageContent['CLD021'] ? pageLanguageContent['CLD021'] : ''}
							</div>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={updateData}>
						{showLoader ? (
							<img src={image_loader} alt='loader' height='20' width='20' />
						) : genPageData['GENSAV'] ? (
							genPageData['GENSAV']
						) : (
							''
						)}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};
export default EditLodgeNameModal;
