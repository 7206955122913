/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardBody, Input } from 'reactstrap'; // eslint-disable-next-line
import avatar_img from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
import Accordion from './Accordion';
import WalletPassAccordion from './WalletPassAccordion';

const UserMemebrShipCardInformation = ({ data, pageLanguageContent, profileId, showTabData }) => {
	const [cardData, setCardData] = useState([]);
	const [showData, setShowData] = useState(false);
	useEffect(() => {
		if (showTabData && cardData.length <= 0) {
			getCardInformationData(profileId);
		}
		// eslint-disable-next-line
	}, [showTabData]);

	function getCardInformationData(id) {
		setShowData(false);
		copiriPortalRequest('/users/' + id + '/cards', config.app_version, 'get', data)
			.then(response => {
				if (response.data.length > 0) {
					setCardData(response.data);
					setShowData(true);
				}
			})
			.catch(error => {
				setCardData([]);
				setShowData(true);
			});
	}
	return (
		<>
			<fieldset className='border p-2'>
				<legend className='w-auto'>{pageLanguageContent['PRL081'] ? pageLanguageContent['PRL081'] : ''}</legend>
				{showData ? (
					<>
						{cardData && cardData.length > 0 ? (
							<>
								<Accordion
									cardData={cardData}
									pageLanguageContent={pageLanguageContent}
									showData={showData}
									pageData={data}
									profileId={profileId}
								/>
								<WalletPassAccordion
									cardData={cardData}
									pageLanguageContent={pageLanguageContent}
									profileId={profileId}
								/>
							</>
						) : (
							<p>{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}</p>
						)}
					</>
				) : (
					<CopiriLoader />
				)}
			</fieldset>
		</>
	);
};
export default UserMemebrShipCardInformation;
