// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
import UserMemberShipInformationAccordionItem from './UserMemberShipInformationAccordionItem'; // eslint-disable-next-line
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap'; // eslint-disable-next-line
import {genPageData} from '../../langconfig/langconfig'; // eslint-disable-next-line
const UserMemberShipInformationAccordion = ({ membershipData, showData, data, pageLanguageContent, profileId }) => {
	const [clicked, setClicked] = useState(0);
	useEffect(() => {
		// eslint-disable-next-line
	}, [showData]);

	const handleToggle = index => {
		if (clicked === index) {
			return setClicked('0');
		}
		setClicked(index);
	};

	return (
		<>
			{membershipData && membershipData.length > 0 ? (
				membershipData.map((item, index) => (
					<UserMemberShipInformationAccordionItem
						onToggle={() => handleToggle(index)}
						active={clicked === index}
						key={index}
						data={item}
						currentIndex={index}
						profileId={profileId}
						pageLanguageContent={pageLanguageContent}
						showChild={true}
					/>
				))
			) : (
				<>{genPageData['GENDNF']?genPageData['GENDNF']:''}</>
			)}
		</>
	);
};

export default UserMemberShipInformationAccordion;
