/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { Fragment, useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Card, CardBody, Input } from 'reactstrap'; // eslint-disable-next-line
import avatar_img from '../../assets/img/team/avatar.svg'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import CopiriLoader from '../common/CopiriLoader';
import EditProfileNameModal from './EditProfileNameModal';
import AddEditProfileAddressModal from './AddEditProfileAddressModal';
import AddEditProfileEmailModal from './AddEditProfileEmailModal';
import AddEditProfilePhoneModal from './AddEditProfilePhoneModal';
import EditProfileDateModal from './EditProfileDateModal';
import { copy } from './Common';
import {genPageData} from '../langconfig/langconfig'; // eslint-disable-next-line
const UserProfilePersonalInforamtion = ({ data, pageLanguageContent, updateData }) => {
	const [showHideEditProfileNameModal, setShowHideEditProfileNameModal] = useState(false); // eslint-disable-next-line
	const [showHideAddEditAddressModal, setShowHideAddEditAddressModal] = useState(false); // eslint-disable-next-line
	const [showHideAddEditEmailModal, setShowHideAddEditEmailModal] = useState(false); // eslint-disable-next-line
	const [showHideAddEditPhoneModal, setShowHideAddEditPhoneModal] = useState(false); // eslint-disable-next-line
	const [countriesData, setCountriesData] = useState([]); // eslint-disable-next-line
	const [stateData, setStateData] = useState([]); // eslint-disable-next-line
	const [type, setType] = useState('');
	const [copiriGenderList, setCopiriGenderList] = useState([]);
	const [showHideEditProfileDateModal, setShowHideEditProfileDateModal] = useState(false); // eslint-disable-next-line
	const [date, setDate] = useState();
	const [dateGenderType, setDateGenderType] = useState('');
	const [inputField, setInputField] = useState({
		first_name: data['first_name'],
		last_name: data['last_name'],
		second_last_name: '',
		middle_name: data['middle_name'],
		name_full: '',
		suffix_id: '',
		suffix: data['suffix'],
		email: '',
		phone: '',
		gender: '',
		gender_id: '',
		dial: '235',
		name_pref: data['name_pref'],
		facility_name: '',
		street_address_1: '',
		street_address_2: '',
		street_address_3: '',
		city: '',
		postal_code: '',
		country: '235',
		birthdate: data['birthdate'],
		state: '',
		spouse_first_name: data?.spouse?.first_name,
		spouse_middle_name: data?.spouse?.middle_name,
		spouse_last_name: data?.spouse?.last_name_one,
		spouse_suffix: data?.spouse?.suffix_id,
		spouse_gender: data?.spouse?.gender,
		spouse_gender_id: data?.spouse?.gender_id,
		spouse_preferred_name: data?.spouse?.preferred_name,
		spouse_second_last_name: data?.spouse?.last_name_two
	});
	useEffect(() => {
		getCopiriGenderList();
		if (data.birthdate) {
			setDate(data.birthdate);
		}
		// eslint-disable-next-line
	}, [data]);

	function getCopiriGenderList() {
		copiriPortalRequest('/canon/lists/copiri_sex', config.app_version, 'get')
			.then(response => {
				if (response.data.length > 0) {
					setCopiriGenderList(response.data);
				}
			})
			.catch(error => {});
	}

	const showHideModal = type => {
		const { first_name, last_name, middle_name, name_full, name_pref, suffix_id, second_last_name,birthdate } = data;
		setInputField({
			...inputField,
			first_name,
			last_name,
			middle_name,
			name_pref,
			name_full,
			suffix_id,
			second_last_name,
			birthdate
		});
		setType(type);
		if (type === 'edit_name') {
			setShowHideEditProfileNameModal(true);
		}
		if (type === 'add_address' || type === 'edit_address') {
			setShowHideAddEditAddressModal(true);
			getCountriesData();
		}

		if (type === 'add_email' || type === 'edit_email') {
			setShowHideAddEditEmailModal(true);
		}

		if (type === 'add_phone' || type === 'edit_phone') {
			setShowHideAddEditPhoneModal(true);
		}
		if (type === 'edit_date') {
			setShowHideEditProfileDateModal(true);
			setDateGenderType('dob');
		}
		if (type === 'edit_gender') {
			setShowHideEditProfileDateModal(true);
			setDateGenderType('gender');
		}

		if (type === 'edit_spouse') {
			setShowHideEditProfileNameModal(true);
		}
		

	};
	const closeAllModals = () => {
		setShowHideEditProfileNameModal(false);
		setShowHideAddEditAddressModal(false);
		setShowHideAddEditEmailModal(false);
		setShowHideAddEditPhoneModal(false);
		setShowHideEditProfileDateModal(false);
	};

	const updateModal = (type, id, inputFields,date ='') => {
		if (type==='edit_date') {
			var mydate = new Date(date);
			const birthdate = mydate.getFullYear() + '-' + (mydate.getMonth() + 1) + '-' + mydate.getDate();
			setDate(birthdate);
		} else {
			updateData(id, inputFields);
		}
	};

	/* function to get country data */
	function getCountriesData() {
		copiriPortalRequest('/canon/countries', config.app_version, 'get')
			.then(response => {
				setCountriesData(response.data);
			})
			.catch(error => {});
	}

	function copyContent(content) {
		copy(content);
	}

	return (
		<>
			<fieldset className='border p-2'>
				<legend className='w-auto'>{pageLanguageContent['PRL041'] ? pageLanguageContent['PRL041'] : ''}</legend>
				<Row>
					<Col lg={8}>
						<strong className='bold-text'>
							{pageLanguageContent['PRL083'] ? pageLanguageContent['PRL083'] : ''}:{' '}
						</strong> 
						 { data['name_full'] }
					</Col>
					<Col lg={4} className='modalRightContent btn-badge'>
						{data['id'] && (
							<Badge
								color={'soft-info'}
								className=''
								onClick={() => showHideModal('edit_name')}
								data-toggle='tooltip'
								title={genPageData['GENEDT'] ? genPageData['GENEDT'] : ''}
							>
								<i className='far fa-edit profileEditContent'></i>
							</Badge>
						)}
					</Col>
				</Row>
				<Row>
					<Col lg={8}>
						<strong className='bold-text'>
							{pageLanguageContent['PRL152'] ? pageLanguageContent['PRL152'] : ''}:
						</strong> 
						<span> { data['birthdate'] ? date : ' ' }</span>
					</Col>
					<Col lg={4} className='modalRightContent btn-badge'>
						<Badge
							color={'soft-info'}
							onClick={() => showHideModal('edit_date')}
							data-toggle='tooltip'
							title={pageLanguageContent['PRL153'] ? pageLanguageContent['PRL153'] : ''}
							className='ml-2 user-copy-icon'
							style={{ display: 'inline-flex' }}
						>
							<i className='far fa-edit'></i>
						</Badge>
					</Col>
				</Row>

				<Row>
					<Col lg={8}>
						<strong className='bold-text'>
							{pageLanguageContent['PRL153'] ? pageLanguageContent['PRL153'] : ''}:
						</strong> 
						<span>  { data['gender']?data['gender']:' ' }</span>
					</Col>
					<Col lg={4} className='modalRightContent btn-badge'>
						<Badge onClick={() => showHideModal('edit_gender')} color={'soft-info'} className='ml-2 user-copy-icon' style={{ display: 'inline-flex' }}> <i className='far fa-edit'></i></Badge>
					</Col>
				</Row>
				<Row>
					<Col lg={8}>
						<strong className='bold-text'>
							{pageLanguageContent['PRL146'] ? pageLanguageContent['PRL146'] : ''}:  
						</strong> 
						<span> { data?.spouse?.first_name}  { data?.spouse?.last_name_one}</span>
					</Col>
					<Col lg={4} className='modalRightContent btn-badge'>
							<Badge
								color={'soft-info'}
								className=''
								onClick={() => showHideModal('edit_spouse')}
								data-toggle='tooltip'
								title={genPageData['GENEDT'] ? genPageData['GENEDT'] : ''}
							>
								<i className='far fa-edit profileEditContent'></i>
							</Badge>
					</Col>
				</Row>
				<Row className='mt-3  '>
					<Col lg={8}>
						<strong className='bold-text'>{pageLanguageContent['PRL084'] ? pageLanguageContent['PRL084'] : ''}</strong>
					</Col>
					{data['id'] && (!data['address'] || data['address'] === '') && (
						<Col lg={4} className='modalRightContent'>
							<Badge color={'soft-secondary'} className='' onClick={() => showHideModal('add_address')}>
								<i className='far fa-plus mr-1'></i>
								{pageLanguageContent['PRL085'] ? pageLanguageContent['PRL085'] : ''}
							</Badge>
						</Col>
					)}
				</Row>

				<Row>
					<Col lg={12} className='mb-1'>
						<hr />
					</Col>
				</Row>

				<Row>
					{data['id'] && data['address'] && data['address'] !== '' ? (
						<>
							<Col lg={8} md='6' sm='12' className='mb-1'>
								<span>Po box 161174, Boiling so, S.C. 19316, United States Of America</span>
							</Col>
							<Col lg={4} md='6' sm='12' className='modalRightContent btn-badge'>
								<Badge color={'soft-info'} className='ml-2' onClick={() => showHideModal('edit_address')}>
									<i className='far fa-edit profileEditContent'></i>
								</Badge>
							</Col>
						</>
					) : (
						<Col lg={12} md='6' sm='12' className='mb-1'>
							<p style={{ textAlign: 'center' }}>
								{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}.
							</p>
						</Col>
					)}
				</Row>

				<br />
				<Row>
					<Col lg={8} className='mb-1'>
						<strong className='bold-text'>{pageLanguageContent['PRL087'] ? pageLanguageContent['PRL087'] : ''}</strong>
					</Col>
						<Col lg={4} className='modalRightContent'>
							<Badge color={'soft-secondary'} className='ml-2' onClick={() => showHideModal('add_email')}>
								<i className='far fa-plus mr-1'></i>
								{pageLanguageContent['PRL088'] ? pageLanguageContent['PRL088'] : ''}
							</Badge>
						</Col>
				</Row>

				<Row>
					<Col lg={12} className='mb-1'>
						<hr />
					</Col>
				</Row>

				<Row>
					{data['email'] && data['email'] !== '' ? (
						<>
							<Col lg={8} md='6' sm='12' className='mb-1'>
								<span>{data['email']}</span>

								{!data['email_validated'] && (
									<Badge color={'soft-danger'} className='mr-2' style={{ marginLeft: '10px' }}>
										<i className='fas fa-exclamation-triangle'></i> Not Verified
									</Badge>
								)}
							</Col>
							<Col lg={4} md='6' sm='12' className='modalRightContent btn-badge'>
								<Badge
									color={'soft-info'}
									className='mr-2 userProfileModalCopy'
									onClick={() => copyContent(data['email'])}
									data-toggle='tooltip'
									title={genPageData['GENCPY'] ? genPageData['GENCPY'] : ''}
								>
									<i className='far fa-copy'></i>
								</Badge>
								<Badge
									color={'soft-info'}
									className=''
									onClick={() => showHideModal('edit_email')}
									data-toggle='tooltip'
									title={genPageData['GENEDT'] ? genPageData['GENEDT'] : ''}
								>
									<i className='far fa-edit profileEditContent'></i>
								</Badge>
							</Col>
						</>
					) : (
						<Col lg={12} md='6' sm='12' className='mb-1'>
							<p style={{ textAlign: 'center' }}>
								{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}.
							</p>
						</Col>
					)}
				</Row>

				<br />

				<Row>
					<Col lg={8}>
						<strong className='bold-text'>{pageLanguageContent['PRL089'] ? pageLanguageContent['PRL089'] : ''}</strong>
					</Col>
					{data['id'] && (!data['phone'] || data['phone'] === '') && (
						<Col lg={4} className='modalRightContent'>
							<Badge color={'soft-secondary'} className='ml-2' onClick={() => showHideModal('add_phone')}>
								<i className='far fa-plus mr-1'></i>
								{pageLanguageContent['PRL090'] ? pageLanguageContent['PRL090'] : ''}
							</Badge>
						</Col>
					)}
				</Row>

				<Row>
					<Col lg={12}>
						<hr />
					</Col>
				</Row>

				<Row>
					{data['phone'] && data['phone'] === '' ? (
						<>
							<Col lg={8} md='6' sm='12' className='mb-1'>
								<span>{data['phone']}</span>
							</Col>
							<Col lg={4} md='6' sm='12' className='modalRightContent btn-badge'>
								<Badge
									color={'soft-info'}
									className='ml-2'
									onClick={() => showHideModal('edit_phone')}
									data-toggle='tooltip'
									title={genPageData['GENEDT'] ? genPageData['GENEDT'] : ''}
								>
									<i className='far fa-edit profileEditContent'></i>
								</Badge>
							</Col>
						</>
					) : (
						<Col lg={12} md='6' sm='12' className='mb-1'>
							<p style={{ textAlign: 'center' }}>
								{pageLanguageContent['PRL086'] ? pageLanguageContent['PRL086'] : ''}.
							</p>
						</Col>
					)}
				</Row>

				<Row className='mt-3  '>
					<Col lg={12}>
						<strong className='bold-text'>{pageLanguageContent['PRL146'] ? pageLanguageContent['PRL146'] : ''}</strong>
					</Col>
					<Col lg={12}>
						<hr />
					</Col>
				</Row>
			</fieldset>

			<EditProfileNameModal
				showHide={showHideEditProfileNameModal}
				onClick={closeAllModals}
				data={data}
				pageLanguageContent={pageLanguageContent}
				setInputField={setInputField}
				inputField={inputField}
				type={type}
				updateModal={updateModal}
				copiriGenderList={copiriGenderList}
			/>
			<AddEditProfileAddressModal
				showHide={showHideAddEditAddressModal}
				onClick={closeAllModals}
				data={data}
				pageLanguageContent={pageLanguageContent}
				countriesData={countriesData}
				setInputField={setInputField}
				inputField={inputField}
				type={type}
				updateModal={updateModal}
			/>

			<AddEditProfileEmailModal
				showHide={showHideAddEditEmailModal}
				onClick={closeAllModals}
				data={data}
				pageLanguageContent={pageLanguageContent}
				countriesData={countriesData}
				type={type}
				setInputField={setInputField}
				inputField={inputField}
				updateModal={updateModal}
			/>

			<AddEditProfilePhoneModal
				showHide={showHideAddEditPhoneModal}
				onClick={closeAllModals}
				data={data}
				pageLanguageContent={pageLanguageContent}
				countriesData={countriesData}
				type={type}
				setInputField={setInputField}
				inputField={inputField}
				updateModal={updateModal}
			/>
			<EditProfileDateModal
				showHide={showHideEditProfileDateModal}
				onClick={closeAllModals}
				data={data}
				pageLanguageContent={pageLanguageContent}
				setInputField={setInputField}
				inputField={inputField}
				type={type}
				updateModal={updateModal}
				copiriGenderList={copiriGenderList}
				dateGenderType={dateGenderType}
			/>
		</>
	);
};
export default UserProfilePersonalInforamtion;
