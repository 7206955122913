/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input } from 'reactstrap'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest } from '../../api/api'; // eslint-disable-next-line
import config from '../../config/config'; // eslint-disable-next-line
import CopiriLoader from '../../common/CopiriLoader';
import avatar_img from '../../../assets/img/team/no_gl_seal_light.png'; // eslint-disable-next-line
import Badge from 'reactstrap/es/Badge'; // eslint-disable-next-line
import AddImageModal from './AddImageModal';
import { toast } from 'react-toastify';
const LodgeModalLogoSection = props => {
	// eslint-disable-next-line
	const { lodgeData, pageLanguageContent, updateLodgeModal } = props;
	const [logoImage, setLogoImage] = useState(avatar_img);
	const [cropImageUrl, setCropImageUrl] = useState(null); // eslint-disable-next-line
	const [sealImage, setSealImage] = useState(''); // eslint-disable-next-line
	const [logoTypeArr, setLogoTypeArr] = useState([]);
	const [showModal1, setShowModal1] = useState(false);
	const [logoType, setLogoType] = useState('logo');
	useEffect(() => {
		/* Showing the loaded data if any*/
		if (lodgeData['id']) {
			if (lodgeData['image']) {
				setLogoImage(lodgeData['image']);
			}
		}
		// eslint-disable-next-line
	}, [lodgeData['id']]);

	/* function to trigger input file */
	function clickInputFile() {
		document.getElementById('uploadCaptureInputFile1').click();
	}
	/* function to get image url for cropping and show modal */
	const getImageUrl = event => {
		setLogoType('logo');
		setShowModal1(!showModal1);
		setCropImageUrl(URL.createObjectURL(event.target.files[0]));
	};
	/* call back function of image uploading to save a new logo image*/
	function callback(image, type = '', inputFile) {
		setLogoImage(image);
		const formdata = new FormData();
		formdata.append('source', 'organization');
		formdata.append('id', lodgeData['id']);
		formdata.append('type', 'logo');
		formdata.append('userimage', inputFile);
		copiriPortalRequest('/images', config.app_version, 'post', formdata)
			.then(response => {
				if (response?.data?.status === 'SUCCESS') {
					toast.success(pageLanguageContent['CLD119']);
				}
			})
			.catch(error => {
				//toast.error(error.response.data.result)
			});
	}
	/* Hide modal */
	const closeModal = () => {
		setShowModal1(!showModal1);
	};

	return (
		<>
			<div className='lodge-user-profile-box'>
				<div className='lodge-user-profile'>
					<img src={logoImage} alt='avatar' />
				</div>
				<div className='lodge-user-btn'>
					<Badge color={'soft-info'} className='mr-1' onClick={() => clickInputFile()}>
						<i className='far fa-edit'></i>
					</Badge>
					<Input type='file' onChange={getImageUrl} id='uploadCaptureInputFile1' accept='.jpg, .jpeg, .png' hidden />
				</div>
			</div>

			<AddImageModal
				showHide={showModal1}
				closeModal={closeModal}
				logoImageCallback={callback}
				cropImageUrl={cropImageUrl}
				pageLanguageContent={props.pageLanguageContent}
				type={logoType}
			/>
		</>
	);
};
export default LodgeModalLogoSection;
