/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';

// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
const UnsavedDataModal = ({ showHide, className, onClick, count, path, updateModal }) => {
	let checkData = JSON.parse(localStorage.getItem('idiom'));
	let pageLanguageContent = '';
	if (checkData) {
		pageLanguageContent = checkData.PRT ? checkData.PRT : '';
	}
	return (
		<div>
			<Modal isOpen={showHide} toggle={onClick}>
				<ModalBody>
					<Form>
						<Row>
							<Col md={12}>
								<p>{pageLanguageContent['PRT153'] ? pageLanguageContent['PRT153'] : ''}</p>
							</Col>
						</Row>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={onClick}>
						{pageLanguageContent['PRT154'] ? pageLanguageContent['PRT154'] : ''}
					</Button>
					<Button color='primary' onClick={updateModal}>
						{pageLanguageContent['PRT155'] ? pageLanguageContent['PRT155'] : ''}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default UnsavedDataModal;
