import React from 'react';
import { Col, Row } from 'reactstrap';
import config from '../config/config';
import powered_by from '../../assets/img/logos/powered_by_copiri.png';
import {genPageData} from '../langconfig/langconfig'; // eslint-disable-next-line
const Footer = () => {
	var today = new Date(),
		date = today.getFullYear();
	return (
		<footer>
			<Row noGutters className='justify-content-between text-center fs--1 mt-4 mb-3'>
				<Col sm='auto'>
					<p className='mb-0 text-600'><img src={powered_by} alt={genPageData['GENPBC']?genPageData['GENPBC']:''} height='20' /> | © {date}</p>
				</Col>
				<Col sm='auto'>
					<p className='mb-0 text-600'>v{config.app_version}</p>
				</Col>
			</Row>
		</footer>
	);
};
export default Footer;
